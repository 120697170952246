import { createStore } from 'vuex'
import {  getAuth, signInWithEmailAndPassword,createUserWithEmailAndPassword, updateProfile, signOut } from "firebase/auth";

export default createStore({
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user(state) {
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    async register(context, { email, password, name }) {
      const auth = getAuth();
      const response = await createUserWithEmailAndPassword(auth, email, password)
      if (response) {
        context.commit('SET_USER', response.user)
        console.log(response.user);
        //response.user.updateProfile({ displayName: name })
      } else {
        throw new Error('Unable to register user')
      }
    },

    async logIn(context, { email, password }) {
      const auth = getAuth();
      const response = await signInWithEmailAndPassword(auth, email, password)
      if (response) {
        context.commit('SET_USER', response.user)
      } else {
        throw new Error('login failed')
      }
    },

    async logOut(context) {
      const auth = getAuth();
      await signOut(auth)
      context.commit('SET_USER', null)
    },

    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        context.commit("SET_USER", null);
      }
    },
    async updateProfileUser() {
      const auth = getAuth();
      console.log(auth.currentUser);
      await updateProfile(auth.currentUser as any, {
        displayName: "Roberto Soares", photoURL: "https://media.licdn.com/dms/image/D4D03AQFVubnuQua21w/profile-displayphoto-shrink_200_200/0/1686156724253?e=1729123200&v=beta&t=O1F4exv5ObPQdMPyVNdZw1dxi8u16VfvRa02qqSA-0g"
      }).then(() => {
        // Profile updated!
        console.log("Nome atualizado com sucesso!");
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  modules: {
  }
})