import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
	"apiKey": "AIzaSyB4VONQC3ASR4XkR1YLu_UwkArVKhatbxo",
	"authDomain": "mokapp-auth.firebaseapp.com",
	"databaseURL": "https://mokapp-auth.firebaseio.com'",
	"projectId": "mokapp-auth",
	"storageBucket": "mokapp-auth.appspot.com",
	"messagingSenderId": "708174353836",
	"appId": "1:708174353836:web:8d0d60dd6182087591d609",
	"measurementId": "G-766MEN08B3"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()


export { app, auth }
