import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7" }
const _hoisted_3 = { class: "col-xxl-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-6 col-md-6" }
const _hoisted_6 = { class: "col-lg-6 col-md-6" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-4" }
const _hoisted_9 = { class: "col-lg-4" }
const _hoisted_10 = { class: "col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")!
  const _component_StatsItem = _resolveComponent("StatsItem")!
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_ResolveComplaints = _resolveComponent("ResolveComplaints")!
  const _component_AverageSpeedOfAnswer = _resolveComponent("AverageSpeedOfAnswer")!
  const _component_NewContact = _resolveComponent("NewContact")!
  const _component_NewUsers = _resolveComponent("NewUsers")!
  const _component_NewLeads = _resolveComponent("NewLeads")!
  const _component_CardsStyle = _resolveComponent("CardsStyle")!
  const _component_CardsStyleTwo = _resolveComponent("CardsStyleTwo")!
  const _component_CardsStyleThree = _resolveComponent("CardsStyleThree")!
  const _component_CardsStyleFour = _resolveComponent("CardsStyleFour")!
  const _component_TimelineCard = _resolveComponent("TimelineCard")!
  const _component_HandlingProjects = _resolveComponent("HandlingProjects")!
  const _component_TeamMember = _resolveComponent("TeamMember")!
  const _component_StoreContent = _resolveComponent("StoreContent")!
  const _component_WhatsHappening = _resolveComponent("WhatsHappening")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Cards" }),
    _createVNode(_component_StatsBoxes),
    _createVNode(_component_StatsItem),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_WhatHappening)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ResolveComplaints)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AverageSpeedOfAnswer)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_NewContact)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_NewUsers)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_NewLeads)
      ])
    ]),
    _createVNode(_component_CardsStyle),
    _createVNode(_component_CardsStyleTwo),
    _createVNode(_component_CardsStyleThree),
    _createVNode(_component_CardsStyleFour),
    _createVNode(_component_TimelineCard),
    _createVNode(_component_HandlingProjects),
    _createVNode(_component_TeamMember),
    _createVNode(_component_StoreContent),
    _createVNode(_component_WhatsHappening)
  ], 64))
}