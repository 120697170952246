import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-6" }
const _hoisted_3 = { class: "col-xxl-6" }
const _hoisted_4 = { class: "col-xxl-6" }
const _hoisted_5 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageRoundedCircle = _resolveComponent("ImageRoundedCircle")!
  const _component_ImageSquareCircle = _resolveComponent("ImageSquareCircle")!
  const _component_AvatarRoundedCircle = _resolveComponent("AvatarRoundedCircle")!
  const _component_AvatarSquareCircle = _resolveComponent("AvatarSquareCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ImageRoundedCircle)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ImageSquareCircle)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AvatarRoundedCircle)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AvatarSquareCircle)
    ])
  ]))
}