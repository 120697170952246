import axios from "axios";
import { ref } from "vue";

const patientsMenu = ref([]);  // Armazena os dados dos pacientes
// Função para buscar os dados da API
const fetchPatients: any = async () => {
  const loading = ref(true) // Controla o estado de carregamento
  const error = ref(null)   // Armazena possíveis erros
  try {
    const response = await axios.get('http://3.221.213.243:3003/api/v1/patients')

    // Armazena o array "results" dos pacientes na constante reativa
    patientsMenu.value = response.data.results
  } catch (err: any) {
    // Armazena o erro na constante reativa
    error.value = err.message || 'Erro ao buscar pacientes'
  } finally {
    // Finaliza o estado de carregamento
    loading.value = false
  }
  
}

const assessments = ref([]) as any;  // Armazena os dados dos pacientes
const fetchAssessmentsApi = async () => {
  const loading = ref(true);
  const error = ref(null);
  try {
    
    const response = await axios.get('http://3.221.213.243:3003/api/v1/assessments')

    // Armazena o array "results" dos pacientes na constante reativa
    assessments.value = response.data.results;
    console.log(assessments.value);
    
  } catch (err: any) {
    // Armazena o erro na constante reativa
    error.value = err.message || 'Erro ao buscar pacientes'
  } finally {
    // Finaliza o estado de carregamento
    loading.value = false
  }
}

export {patientsMenu, fetchPatients, assessments,fetchAssessmentsApi}