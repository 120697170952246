<template>
  <MainHeader v-if="userVerify" />
  <MainSidebar v-if="userVerify" />
  <div class="main-content d-flex flex-column transition overflow-hidden">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <MainFooter v-if="userVerify" />
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import stateStore from "./utils/store";
import { useStore } from "vuex";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      UserOnline: {email:''},
      userVerify: false,
    }
  },
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
    this.verifyOn();
  },
  setup() {
    const stateStoreInstance = stateStore;
    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
       
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");

      }
    });
    return {};
  },
  created() {
    this.verifyOn();
    this.readOn();
  },
  methods: {
    verifyOn(){
      const store = useStore();
      if(store && 'getters' in store && store?.getters !== undefined){
        this.UserOnline = store.getters.user.data;
        console.log(this.UserOnline);
        if(this.UserOnline !== null) {
          this.tempSave(JSON.stringify(this.UserOnline));
          this.userVerify = true;
        }
      } else if(this.UserOnline === null) {
        this.userVerify = false;
      } else {
        this.userVerify = false;
      }
      this.readOn();
    },
    tempSave(user:any) {
      localStorage.setItem('temp_access', "");
      localStorage.setItem('temp_access', user);
    },
    readOn (){
      
      if (localStorage.getItem('temp_access')) {
        this.userVerify = true;
        return true;
      } else {
        this.userVerify = false;
      }
    },
  },
});
</script>
<style>
/* Transição de fade */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 2;
}
</style>