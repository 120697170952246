export const storageService = {
    setItem(key: string, value:any) {
      localStorage.setItem(key, JSON.stringify(value));
      window.dispatchEvent(new CustomEvent("localStorageUpdate", { detail: { key, value } }));
    },
    getItem(key:string) {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    },
    removeItem(key: string) {
      localStorage.removeItem(key);
      window.dispatchEvent(new CustomEvent("localStorageUpdate", { detail: { key, value: null } }));
    }
  };