import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }
const _hoisted_4 = { class: "col-lg-6" }
const _hoisted_5 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicAlerts = _resolveComponent("BasicAlerts")!
  const _component_DismissingAlerts = _resolveComponent("DismissingAlerts")!
  const _component_IconsAlerts = _resolveComponent("IconsAlerts")!
  const _component_AdditionalContentAlerts = _resolveComponent("AdditionalContentAlerts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BasicAlerts)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DismissingAlerts)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_IconsAlerts)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AdditionalContentAlerts)
    ])
  ]))
}