<template>

    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h4 class="mb-5 mb-sm-0 fw-bold">{{ Lang("CountermovementJump") }}</h4>
    </div>

    <!-- Loading GIF -->
    <div class="row loading" v-if="isLoading">

        <img style="width: 150px;margin-bottom: 400px;" src="/images/loading.gif" alt="Carregando..." />


    </div>
    <div class="row" v-else>
        <div class="col-12">
            <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
                <div class="card-body p-15 p-sm-20 p-md-25">
                    <div class="table-responsive">
                        <table class="table text-nowrap align-middle mb-0">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0 text-center">
                                        Atleta</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Quantidade </th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Última Avaliação</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Altura Máxima (cm)</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Potência (W/kg)</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        RSI</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Deslocamento Horizontal (cm)</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Valgo Direita</th>
                                    <th scope="col"
                                        class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-center">
                                        Valgo Esquerda</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="patient in patientsData" :key="patient.fileName">
                                    <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                                                <!-- <img src="./Dashboard_files/user3.cd403a78.jpg" class="rounded-circle me-8" width="24" height="24" alt="user"> -->
                                                {{ patient.content.demographic.name }}
                                            </div>
                                        </div>
                                    </th>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">
                                        {{ countDateRepetitions(patient.content.performance.dates) }}
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">
                                        {{ getLatestDate(patient.content.performance.dates) }}
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">
                                        <!-- REMOVIDO LOGICA v-if="!(patient.content.performance.jump_heights_max.perc_variation === 0)" -->
                                        {{ patient.content.performance.jump_heights_max.current }}
                                        <div :class="showClassUpOrDown(patient.content.performance.jump_heights_max.perc_variation)"   v-html="loadingPercentage(patient.content.performance.jump_heights_max.perc_variation )"></div>
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center"> 
                                        {{ patient.content.performance.peak_powers_max.current }}
                                        <div :class="showClassUpOrDown(patient.content.performance.peak_powers_max.perc_variation)"  v-html="loadingPercentage(patient.content.performance.peak_powers_max.perc_variation )"></div>
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">
                                        {{ patient.content.performance.adjusted_RSIs_mean.current }}
                                        <div 
                                            :class="showClassUpOrDown(patient.content.performance.adjusted_RSIs_mean.perc_variation)"
                                            v-html="loadingPercentage(patient.content.performance.adjusted_RSIs_mean.perc_variation )"></div>

                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">
                                        <!-- DESLOCAMENTO HORIZONTAL -->
                                        {{ patient.content.performance.body_displacemente.current }}
                                        <div
                                            :class="showClassUpOrDownInvert(patient.content.performance.body_displacemente.perc_variation)" 
                                            v-html="loadingPercentageInvert(patient.content.performance.body_displacemente.perc_variation)"></div>

                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">
                                        
                                        {{  (patient.content.kinematic.Knee.Valgus_count.right.phases.propulsive.values.slice(-1)[0] === null)? 0 : patient.content.kinematic.Knee.Varus_Valgus.right.phases.propulsive.values.slice(-1)[0] }}     
                                        <div 
                                        :class="showClassUpOrDown(patient.content.kinematic.Knee.Valgus_count.right.phases.propulsive.values.slice(-1)[0])" 
                                        v-html="loadingPercentage(patient.content.kinematic.Knee.Valgus_count.right.phases.propulsive.perc_variation)"></div>
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis text-center">

                                        {{  (patient.content.kinematic.Knee.Valgus_count.left.phases.propulsive.values.slice(-1)[0] === null)? 0 : patient.content.kinematic.Knee.Varus_Valgus.left.phases.propulsive.values.slice(-1)[0] }}     
                                        <div :class="showClassUpOrDown(patient.content.kinematic.Knee.Valgus_count.left.phases.propulsive.values.slice(-1)[0])"  v-html="loadingPercentage(patient.content.kinematic.Knee.Valgus_count.left.phases.propulsive.perc_variation)"></div>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>


</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
//multi idiomas
import { t as lang, switchLanguage } from '@/plugins/loadMultiLanguage';

interface IsystemOn{
    id: string;
    name: string;
    country: string;
    language: string;
    contact: string;
    created_at?: string;
    updated_at?: string;
}

export default defineComponent({
    name: 'CounterMovementJumps',
    setup(props, { expose }) {
        const langString: any = (localStorage.getItem('lang_var') != null) ? localStorage.getItem('lang_var') : 'pt';
        // Observar mudanças em 'inputText'
        watch(langString, (newValue, oldValue) => {
            switchLanguage(newValue);
        });

        return {
            langString
        };
    },
    mounted() {
        switchLanguage(this.langString);
        this.patientsJson = this.getPatientsBucket();
    },
    data() {
        return {
            patientsJson: [] as any,
            patientsData: [] as any,
            isLoading: true,
        };
    },
    created() {
        setTimeout(() => {
            this.fetchPatientData();
        }, 500);
       
    },
    methods: {
        getPatientsBucket(){
            let patientsBucket: [] | any = localStorage.getItem('patients_bucket_json');
    
            if(patientsBucket || patientsBucket.length > 0){
                patientsBucket = JSON.parse(patientsBucket);
                return patientsBucket;
            }
            return [];
        },
        getSystemId(){
            let systemOn:string | any | IsystemOn = localStorage.getItem('on_system');
    
            if(systemOn){
                systemOn = JSON.parse(atob(systemOn));
                return systemOn.id;
            }
            return "";
        },
        async fetchPatientData() {
            try {
                const patientID =   this.getSystemId();
                const responses = await Promise.all(
                    this.patientsJson.map(fileName =>
                        fetch(`https://api.mokapp.app.br/api/v1/get-file?fileName=${fileName}&folder=${patientID}/CMJ5`)
                            .then(response => response.json())
                    )
                );
                this.patientsData = responses;
            } catch (error) {
                console.error("Erro ao buscar dados dos pacientes:", error);
            } finally {
                this.isLoading = false; // Define isLoading como false após o carregamento
            }
        },
        getLatestDate(dates) {
            return dates.reduce((latest, current) => {
                const latestDate = new Date(latest.split('/').reverse().join('-'));
                const currentDate = new Date(current.split('/').reverse().join('-'));
                return latestDate > currentDate ? latest : current;
            });
        },
        countDateRepetitions(dates){
            return dates.length;
        },
        Lang(param1: string, param2=""){
            return lang(param1, param2);
        },
        loadingPercentage(jump_heights_max_perc_variation: number | any){
            if(!this.isNull(jump_heights_max_perc_variation)){
                let iconUpDown = '';
                let classPergentage = (jump_heights_max_perc_variation < 1)? 'fw-bold text-danger ': 'fw-bold text-success ';
                classPergentage = (jump_heights_max_perc_variation === 0)? 'fw-bold text-black d-inline-block' : classPergentage;
                if(jump_heights_max_perc_variation > 0){
                    iconUpDown = '<i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"></i>';
                } else if(jump_heights_max_perc_variation === 0){
                    iconUpDown = '<i style="color: black;" class="flaticon-black-circle fs-11 lh-1 position-relative top-1"></i>';
                } else {
                    iconUpDown = '<i v-else class="flaticon-down-arrow fs-11 lh-1 position-relative top-1" data-v-98135cde=""></i>';
                }
                return `<span class="${classPergentage}">
                            ${iconUpDown }
                            ${jump_heights_max_perc_variation}% 
                        </span>`;
            }
            return "";
            
        },
        loadingPercentageInvert(jump_heights_max_perc_variation: number | any){
            if(!this.isNull(jump_heights_max_perc_variation)){
                let iconUpDown = '';
                let classPergentage = (jump_heights_max_perc_variation < 1)?  'fw-bold text-success ' : 'fw-bold text-danger ';
                classPergentage = (jump_heights_max_perc_variation === 0)? 'fw-bold text-black d-inline-block': classPergentage;
                if(jump_heights_max_perc_variation > 0){
                    iconUpDown = '<i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"></i>';
                } else if(jump_heights_max_perc_variation === 0){
                    iconUpDown = '<i style="color: black;" class="flaticon-black-circle fs-11 lh-1 position-relative top-1"></i>';
                } else {
                    iconUpDown = '<i v-else class="flaticon-down-arrow fs-11 lh-1 position-relative top-1" data-v-98135cde=""></i>';
                }
                return `<span class="${classPergentage}">
                            ${iconUpDown }
                            ${jump_heights_max_perc_variation}% 
                        </span>`;
            }
            return "";
            
        },
        isNull(value: any): boolean {
            return value === null;
        },
        showClassUpOrDown(Value)
        {
            if(parseInt(Value) > 0)
            {
                return "badge text-outline-success";
            } else if(parseInt(Value) === 0){
                return "badge text-outline-info";
            }
            return "badge text-outline-danger";
        },
        showClassUpOrDownInvert(Value)
        {
            if(!(parseInt(Value) > 0))
            {
                return "badge text-outline-success";
            } else if(parseInt(Value) === 0){
                return "badge text-outline-info";
            }
            return "badge text-outline-danger";
        },
    },
});
</script>
<style>
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Altura total da tela */
}
</style>