<template>
  <header
    :class="[
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
      { sticky: isSticky },
    ]"
    id="header"
  >
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div
          class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center"
        >
          <button
            class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
            id="header-burger-menu"
            @click="stateStoreInstance.onChange"
          >
            <i class="flaticon-menu-3"></i>
          </button>
          <!--
            <form class="search-box">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control shadow-none rounded-0 border-0"
                  placeholder="Search here"
                />
                <button
                  class="default-btn position-relative transition border-0 fw-medium text-white pt-8 pb-8 ps-15 pe-15 pt-md-12 pb-md-12 ps-md-20 pe-md-20"
                  type="button"
                >
                  Search
                  <i
                    class="flaticon-search-interface-symbol position-relative ms-5 top-1"
                  ></i>
                </button>
              </div>
            </form>
          -->
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-6">
        <div
          class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end"
        >
         
          <LightDarkSwtichBtn />
          

          <div class="dropdown language-dropdown">
            <button
              class="dropdown-toggle fw-medium position-relative pt-0 pb-0 bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-translate"></i>
              {{ languageSelected }}
            </button>
            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <div
                class="title d-flex align-items-center justify-content-between"
              >
                <span class="text-black fw-bold">Choose Language</span>
              </div>
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li class="position-relative fw-semibold text-black-emphasis">
                  <img
                    
                    src="../../assets/images/flag/usa.png"
                    width="30"
                    height="30"
                    class="rounded-circle position-absolute"
                    alt="flag"
                  />
                  English
                  <a
                    v-on:click="langSelectSave('en');reloadNowPage()"
                    href="#en"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></a>
                </li>
                <li class="position-relative fw-semibold text-black-emphasis">
                  <img
                    
                    src="../../assets/images/flag/brazil-2.png"
                    width="32"
                    height="32"
                    class="rounded-circle position-absolute"
                    alt="flag"
                  />
                  Portuguese
                  <a
                    v-on:click="langSelectSave('pt');reloadNowPage()"
                    href="#pt"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></a>
                </li>
                
              </ul>
            </div>
          </div>

          <!--
          <div class="dropdown notification-dropdown">
            <button
              class="dropdown-toggle p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-bell-2"></i>
              <span
                class="dot-badge position-absolute fw-bold rounded-circle text-white bg-primary d-inline-block"
              >
                3
              </span>
            </button>
            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <div
                class="title d-flex align-items-center justify-content-between"
              >
                <span class="text-black fw-bold"
                  >Notifications <span class="text-muted">(03)</span></span
                >
                <router-link
                  to="/notifications"
                  class="text-decoration-none link-btn transition text-primary fw-medium"
                >
                  Clear All
                </router-link>
              </div>
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li class="text-muted position-relative">
                  <div
                    class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-chat-centered-dots"></i>
                  </div>
                  <span class="d-block text-black-emphasis"
                    >You have requested to
                    <strong class="fw-semibold">withdrawal</strong></span
                  >
                  2 hrs ago
                  <router-link
                    to="/notifications"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                  <span
                    class="unread d-inline-block rounded-circle bg-primary position-absolute w-10 h-10"
                  ></span>
                </li>
                <li class="text-muted position-relative">
                  <div
                    class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-user-plus"></i>
                  </div>
                  <span class="d-block text-black-emphasis">
                    <strong class="fw-semibold">A new user</strong> added in
                    Adlash
                  </span>
                  3 hrs ago
                  <router-link
                    to="/notifications"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                  <span
                    class="unread d-inline-block rounded-circle bg-primary position-absolute w-10 h-10"
                  ></span>
                </li>
                <li class="text-muted position-relative">
                  <div
                    class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-chat-centered-dots"></i>
                  </div>
                  <span class="d-block text-black-emphasis">
                    You have requested to
                    <strong class="fw-semibold">withdrawal</strong>
                  </span>
                  1 day ago
                  <router-link
                    to="/notifications"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
              </ul>
              <div class="text-center dropdown-footer">
                <router-link
                  to="/notifications"
                  class="link-btn text-decoration-none text-primary position-relative d-inline-block transition fw-medium fw-medium"
                >
                  See All Notifications
                </router-link>
              </div>
            </div>
          </div>
          -->

          <div class="dropdown profile-dropdown">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="logoSystem"
                class="rounded"
                width="44"
                height="44"
                alt="admin"
              />
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8"> {{loadingNamePerfil()}}</span>
                <span class="text-body-emphasis fw-semibold fs-13">Admin</span>
              </span>
            </button>
            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <!--
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-user-2"></i>
                  My Account
                  <router-link
                    to="/profile"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-setting"></i>
                  Settings
                  <router-link
                    to="/profile-settings"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-warning"></i>
                  Support
                  <router-link
                    to="/support-desk-card"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                -->
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-logout"></i>
                  Logout
                  <router-link
                    to="/logout"
                    @click="LogoutNow()"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import LightDarkSwtichBtn from "./LightDarkSwtichBtn.vue";
import stateStore from "../../utils/store";
import { patientsMenu} from "@/utils/patientsJsonApi";
import configs from "@/utils/configs";

const assessments = ref([]) as any;  // Armazena os dados dos assessments
const systemsApi = ref([]) as any; // Armazena os dados dos systems
 // Função para buscar os dados da API
const fetchAssessments = async () => {
  const loading = ref(true) // Controla o estado de carregamento
  const error = ref(null)   // Armazena possíveis erros
  try {
    const response = await fetch(configs.urlApiFiles+'/api/v1/assessments')
    
    if (!response.ok) {
      throw new Error('Erro ao buscar pacientes')
    }

    const data = await response.json()
    assessments.value = data.results  // Armazena o array "results" dos pacientes na constante reativa
    
    //armazenar os assessments coletados relacionado ao filtro criado com o user
    let systemOn:string | any = localStorage.getItem('on_system');
        
    if(systemOn){
      systemOn = JSON.parse(atob(systemOn));
      //armazenar os dados relacionados a pacientId com o systemId
      let contMore: any=[];

      assessments.value.forEach(access => {
        if(systemOn.id === access.system){
          contMore.push(access);
        }
      });
      localStorage.setItem('on_assessments','');
      localStorage.removeItem('on_assessments');
      //convert para base64 [btoa] e reverte com [atob()]
      localStorage.setItem('on_assessments',btoa(JSON.stringify(contMore)));
    }
  } catch (err: any) {
    error.value = err.message  // Armazena o erro na constante reativa
  } finally {
    loading.value = false  // Finaliza o estado de carregamento
  }
}

const fetchSystems = async () => {
  const loading = ref(true) // Controla o estado de carregamento
  const error = ref(null)   // Armazena possíveis erros
  try {
    const response = await fetch(configs.urlApiFiles + '/api/v1/systems?format=json&limit=200')
    
    if (!response.ok) {
      throw new Error('Erro ao buscar Systems')
    }

    const data = await response.json()
    systemsApi.value = data.results  // Armazena o array "results" dos pacientes na constante reativa
    const userOn:string | any = localStorage.getItem('temp_access');
    
    if(userOn){
      const userOnObject = JSON.parse(userOn);
        //convert para base64 [btoa] e reverte com [atob()]
      
      data.results.forEach(system => {
        console.log(system.contact);
        if(userOnObject.email === system.contact){
          //definir infos de system
          //localStorage.setItem('on_system',btoa(JSON.stringify(system)));
          localStorage.removeItem('on_system');
          //convert para base64 [btoa] e reverte com [atob()]
          localStorage.setItem('on_system',btoa(JSON.stringify(system)));
        }
        
      });
    }
  } catch (err: any) {
    error.value = err.message  // Armazena o erro na constante reativa
  } finally {
    loading.value = false  // Finaliza o estado de carregamento
  }
}
import onSystem from "@/utils/getSystemOn";
let idSystem = await onSystem();
export default defineComponent({
  name: "MainHeader",
  components: {
    LightDarkSwtichBtn,
  },
  data(){
    return {
      assessmentsObject: [] as any,
      logoSystem: (idSystem === "dfcc87e4-51c1-43b2-9fec-c5ffe227e369")? '/images/profile-sesc-flamengo.png' : '/images/logo_system_default.jpeg',
    }
  },
  created(){
    //verificar dados do system ao usser online
    //this.storeSystemAccess();
  },
  methods: {
    LogoutNow() {
      if (localStorage.getItem('temp_access')) {
        localStorage.removeItem('temp_access');
        setTimeout(() => {
          window.location.href = '/login';  
        }, 2000);
        
      }
    },
    langSelectSave(lang="") {

      if(lang !==""){
        
        localStorage.removeItem('lang_var');
        localStorage.setItem('lang_var', lang);
      }
      
    },
    loadingNamePerfil(){
      //comparar usuario logado com ID de usuario
      const userOn:string | null = localStorage.getItem('temp_access');
      let nameUser = '';
      if(userOn){
        const userOnObject = JSON.parse(userOn);
        const userName =  userOnObject.email.split('@')[0];

        patientsMenu.value.forEach((pacient: any) =>{
          if(pacient.id === userOnObject.uuid){
            nameUser = pacient.name;
          }
        });
        nameUser =(nameUser !== "")? nameUser : userName;
        return nameUser;
      }
    },
  },
  setup() {
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);
    const urlNow = window.location.href;
    let languageSelected = 'EN';
    
    const reloadNowPage = () => {
      return  window.location.href = urlNow;
    }

    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
      fetchAssessments();
      fetchSystems();
    });
    const langActive = localStorage.getItem('lang_var');
    if(langActive === 'en'){
      languageSelected = 'EN';
    } else {
      languageSelected = 'PT';
    }

    return {
      isSticky,
      stateStoreInstance,
      reloadNowPage,
      languageSelected,
      assessments
    };
  },
});
</script>