<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-md-5 mb-lg-10">Additive</h5>
      <span
        class="border d-inline-block me-10 mt-15 bg-gray w-110 h-110"
      ></span>
      <span
        class="border-top d-inline-block me-10 mt-15 bg-gray w-110 h-110"
      ></span>
      <span
        class="border-end d-inline-block me-10 mt-15 bg-gray w-110 h-110"
      ></span>
      <span
        class="border-bottom d-inline-block me-10 mt-15 bg-gray w-110 h-110"
      ></span>
      <span
        class="border-start d-inline-block me-10 mt-15 bg-gray w-110 h-110"
      ></span>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#additiveBordersCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="additiveBordersCode">
<div>&lt;span class="border"&gt;&lt;/span&gt;</div>
<div>&lt;span class="border-top"&gt;&lt;/span&gt;</div>
<div>&lt;span class="border-end"&gt;&lt;/span&gt;</div>
<div>&lt;span class="border-bottom"&gt;&lt;/span&gt;</div>
<div>&lt;span class="border-start"&gt;&lt;/span&gt;</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-md-5 mb-lg-10">Subtractive</h5>
      <span
        class="border border-0 d-inline-block bg-gray me-10 mt-15 w-110 h-110"
      ></span>
      <span
        class="border border-top-0 d-inline-block bg-gray me-10 mt-15 w-110 h-110"
      ></span>
      <span
        class="border border-end-0 d-inline-block bg-gray me-10 mt-15 w-110 h-110"
      ></span>
      <span
        class="border border-bottom-0 d-inline-block bg-gray me-10 mt-15 w-110 h-110"
      ></span>
      <span
        class="border border-start-0 d-inline-block bg-gray me-10 mt-15 w-110 h-110"
      ></span>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#subtractiveBordersCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="subtractiveBordersCode">
&lt;span class="border border-0"&gt;&lt;/span&gt;
&lt;span class="border border-top-0"&gt;&lt;/span&gt;
&lt;span class="border border-end-0"&gt;&lt;/span&gt;
&lt;span class="border border-bottom-0"&gt;&lt;/span&gt;
&lt;span class="border border-start-0"&gt;&lt;/span&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-md-5 mb-lg-10">Color</h5>
      <span
        class="border border-primary d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-primary-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-secondary d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-secondary-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-success d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-success-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-danger d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-danger-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-warning d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-warning-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-info d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-info-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-light d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-light-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-dark d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-dark-subtle d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-black d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <span
        class="border border-white d-inline-block bg-gray w-110 h-110 me-10 mt-15"
      ></span>
      <div class="position-relative mt-15 mt-md-20 mb-15 mb-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#bordersColorCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="bordersColorCode">
&lt;span class="border border-primary"&gt;&lt;/span&gt;
&lt;span class="border border-primary-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-secondary"&gt;&lt;/span&gt;
&lt;span class="border border-secondary-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-success"&gt;&lt;/span&gt;
&lt;span class="border border-success-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-danger"&gt;&lt;/span&gt;
&lt;span class="border border-danger-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-warning"&gt;&lt;/span&gt;
&lt;span class="border border-warning-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-info"&gt;&lt;/span&gt;
&lt;span class="border border-info-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-light"&gt;&lt;/span&gt;
&lt;span class="border border-light-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-dark"&gt;&lt;/span&gt;
&lt;span class="border border-dark-subtle"&gt;&lt;/span&gt;
&lt;span class="border border-black"&gt;&lt;/span&gt;
&lt;span class="border border-white"&gt;&lt;/span&gt;
</code>
</pre>
      </div>
      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
        Or modify the default <code>border-color</code> of a component:
      </p>
      <div class="mb-4">
        <label for="exampleFormControlInput1" class="form-label"
          >Email address</label
        >
        <input
          type="email"
          class="form-control border-success"
          id="exampleFormControlInput1"
          placeholder="name@example.com"
        />
      </div>
      <div class="h4 pb-2 mb-4 text-danger border-bottom border-danger">
        Dangerous heading
      </div>
      <div
        class="p-3 bg-dark bg-opacity-10 border border-dark border-start-0 rounded-end"
      >
        Changing border color and width
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#formColorCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="formColorCode">
<div>&lt;div class="mb-4"&gt;</div>
    <div>&lt;label for="exampleFormControlInput1" class="form-label"&gt;Email address&lt;/label&gt;</div>
    <div>&lt;input type="email" class="form-control border-success" id="exampleFormControlInput1" placeholder="name@example.com"&gt;</div>
<div>&lt;/div&gt;</div>
<div>&lt;div class="h4 pb-2 mb-4 text-danger border-bottom border-danger"&gt;</div>
    <div>Dangerous heading</div>
<div>&lt;/div&gt;</div>
<div>&lt;div class="p-3 bg-dark bg-opacity-10 border border-dark border-start-0 rounded-end"&gt;</div>
    <div>Changing border color and width</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-20 mb-lg-25">Additive</h5>
      <div class="border border-success mt-15 fw-medium p-2 mb-2">
        This is default success border
      </div>
      <div
        class="border border-success mt-15 fw-medium p-2 mb-2 border-opacity-75"
      >
        This is 75% opacity success border
      </div>
      <div
        class="border border-success mt-15 fw-medium p-2 mb-2 border-opacity-50"
      >
        This is 50% opacity success border
      </div>
      <div
        class="border border-success mt-15 fw-medium p-2 mb-2 border-opacity-25"
      >
        This is 25% opacity success border
      </div>
      <div class="border border-success mt-15 fw-medium p-2 border-opacity-10">
        This is 10% opacity success border
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#bordersOpacityCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="bordersOpacityCode">
&lt;div class="border border-success p-2 mb-2"&gt;This is default success border&lt;/div&gt;
&lt;div class="border border-success p-2 mb-2 border-opacity-75"&gt;This is 75% opacity success border&lt;/div&gt;
&lt;div class="border border-success p-2 mb-2 border-opacity-50"&gt;This is 50% opacity success border&lt;/div&gt;
&lt;div class="border border-success p-2 mb-2 border-opacity-25"&gt;This is 25% opacity success border&lt;/div&gt;
&lt;div class="border border-success p-2 border-opacity-10"&gt;This is 10% opacity success border&lt;/div&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-md-5 mb-lg-10">Width</h5>
      <span class="border border-1 d-inline-block me-10 mt-15 w-90 h-90"></span>
      <span class="border border-2 d-inline-block me-10 mt-15 w-90 h-90"></span>
      <span class="border border-3 d-inline-block me-10 mt-15 w-90 h-90"></span>
      <span class="border border-4 d-inline-block me-10 mt-15 w-90 h-90"></span>
      <span class="border border-5 d-inline-block me-10 mt-15 w-90 h-90"></span>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#widthBordersCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="widthBordersCode">
&lt;span class="border border-1"&gt;&lt;/span&gt;
&lt;span class="border border-2"&gt;&lt;/span&gt;
&lt;span class="border border-3"&gt;&lt;/span&gt;
&lt;span class="border border-4"&gt;&lt;/span&gt;
&lt;span class="border border-5"&gt;&lt;/span&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-md-5 mb-lg-10">Radius</h5>
      <span
        class="rounded bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-top bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-end bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-bottom bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-start bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-circle bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-pill bg-secondary d-inline-block me-10 mt-15 w-110 h-70"
      ></span>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#radiusBordersCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="radiusBordersCode">
&lt;img src="..." class="rounded" alt="..."&gt;
&lt;img src="..." class="rounded-top" alt="..."&gt;
&lt;img src="..." class="rounded-end" alt="..."&gt;
&lt;img src="..." class="rounded-bottom" alt="..."&gt;
&lt;img src="..." class="rounded-start" alt="..."&gt;
&lt;img src="..." class="rounded-circle" alt="..."&gt;
&lt;img src="..." class="rounded-pill" alt="..."&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-md-5 mb-lg-10">Radius</h5>
      <span
        class="rounded-0 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-1 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-2 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-3 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-4 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-5 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <div class="position-relative mt-15 mt-md-20 mb-15 mb-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#sizesBordersCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="sizesBordersCode">
&lt;img src="..." class="rounded-0" alt="..."&gt;
&lt;img src="..." class="rounded-1" alt="..."&gt;
&lt;img src="..." class="rounded-2" alt="..."&gt;
&lt;img src="..." class="rounded-3" alt="..."&gt;
&lt;img src="..." class="rounded-4" alt="..."&gt;
&lt;img src="..." class="rounded-5" alt="..."&gt;
</code>
</pre>
      </div>
      <span
        class="rounded-bottom-1 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-start-2 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-end-circle bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-start-pill bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <span
        class="rounded-5 rounded-top-0 bg-secondary d-inline-block me-10 mt-15 w-70 h-70"
      ></span>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#sizesBordersCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="sizesBordersCode">
&lt;img src="..." class="rounded-bottom-1" alt="..."&gt;
&lt;img src="..." class="rounded-start-2" alt="..."&gt;
&lt;img src="..." class="rounded-end-circle" alt="..."&gt;
&lt;img src="..." class="rounded-start-pill" alt="..."&gt;
&lt;img src="..." class="rounded-5 rounded-top-0" alt="..."&gt;
</code>
</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BordersContent",
};
</script>