<template>
  <div class="row">
    <div class="col-xxxl-8">
      <div class="card mb-25 border-0 rounded-0 bg-white event-details-card">
        <div class="card-body p-10 letter-spacing">
          <img
            src="../../../assets/images/events/event1.jpg"
            class="mb-15 mb-sm-20 mb-md-25 md-lg-30"
            alt="event-image"
          />
          <div class="ps-5 pe-5 mb-15 ps-sm-20 pe-sm-20 mb-sm-25">
            <div
              class="mb-12 mb-md-20 d-lg-flex align-items-center justify-content-between"
            >
              <div class="d-lg-flex align-items-center">
                <div class="date rounded-1 text-center">
                  <span class="d-block bg-primary text-white fs-md-15 fw-bold">
                    Mar
                  </span>
                  <span class="d-block text-black fw-black">31</span>
                </div>
                <div class="ms-lg-20 mt-15 mt-lg-0">
                  <h5 class="fs-15 fs-md-16 fs-lg-18 mb-8 mb-md-12 fw-bold">
                    <a href="#" class="text-black text-decoration-none">
                      UI/UX Design Sprint 2023
                    </a>
                  </h5>
                  <div class="d-flex align-items-center">
                    <span class="d-block text-muted">
                      Organized by
                      <span class="fw-semibold text-primary"> EnvyTheme </span>
                    </span>
                    <span class="badge text-outline-success ms-10">Open</span>
                  </div>
                </div>
              </div>
              <div
                class="buttons-list mt-12 mt-lg-0 d-sm-flex align-items-center"
              >
                <button
                  type="button"
                  class="border-0 rounded-1 fs-14 fw-medium position-relative d-inline-block bg-primary text-white transition"
                >
                  Get Ticket
                  <i class="flaticon-plus text-white"></i>
                </button>
                <button
                  type="button"
                  class="border-0 rounded-1 fs-14 fw-medium position-relative d-inline-block transition text-primary"
                >
                  Add To Calendar
                  <i class="flaticon-calendar text-primary"></i>
                </button>
                <button
                  type="button"
                  class="border-0 rounded-1 fs-14 fw-medium position-relative d-inline-block transition text-primary"
                >
                  1201
                  <i class="flaticon-heart-2 text-danger"></i>
                </button>
                <button
                  type="button"
                  class="border-0 rounded-1 fs-14 fw-medium position-relative d-inline-block transition text-primary"
                >
                  <i class="flaticon-share text-primary"></i>
                </button>
              </div>
            </div>
            <p class="text-paragraph fs-md-15 fs-lg-16 text-paragraph lh-base">
              This event combines all the data and backend information to the
              frontend. Organized by EnvyTheme. This event combines all the data
              and backend information to the frontend. Organized by EnvyTheme.
            </p>
          </div>
          <div class="info bg-gray">
            <div class="row">
              <div class="col-sm-6 col-lg-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-info rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-clock"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Time:
                  </span>
                  <span class="fw-medium text-paragraph">
                    11:30 AM - 12:30 PM
                  </span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-success rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-maps-and-flags"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Location:
                  </span>
                  <span class="fw-medium text-paragraph">
                    795 Folsom Ave, San Francisco
                  </span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-warning rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-money"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Ticket Price:
                  </span>
                  <span class="fw-medium text-paragraph">$ 20</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-2">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-danger rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-date-1"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Seat:
                  </span>
                  <span class="fw-medium text-paragraph">120</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white event-details-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div class="mb-10 mb-sm-15 mb-lg-20">
            <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
              About The Event
            </h5>
          </div>
          <p class="text-paragraph fs-md-15 fs-lg-16 text-paragraph lh-base">
            Combine the power of Data Science, Machine Learning and Deep
            Learning to create powerful AI for Real-World applications! Learn
            key AI concepts and intuition <u>training</u> to get you quickly up
            to speed with all things AI.
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 text-paragraph lh-base">
            We’re fully committed to making this the most accessible and
            results-driven AI course on the planet. This
            <strong>requires</strong> us to be there when you need our help.
            That’s why we’ve put together a team of professional Data Scientists
            to support you in your journey, <i>meaning</i> you’ll get a response
            from us within 48 hours maximum.
          </p>
        </div>
      </div>
    </div>
    <div class="col-xxxl-4">
      <EventSpeakers />
      <TopicToBeCovered />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EventSpeakers from "./EventSpeakers.vue";
import TopicToBeCovered from "./TopicToBeCovered.vue";

export default defineComponent({
  name: "EventDetails",
  components: {
    EventSpeakers,
    TopicToBeCovered,
  },
});
</script>