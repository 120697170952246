<template>
  <div v-if="patient.demographic">

    <div v-if="!verifyGraph && !verifyGraphKine && !verifyGrapFootDisplacement">
    
    
      <div class="row" v-if="patient.demographic">
          <div class="col-xxl-12 col-sm-12">
              <div class="card mb-20 border-0 rounded-0 stats-item bg-white">
                  <div class="card-body p-0 p-sm-0 p-md-0 p-lg-0 letter-spacing">
                      <div  v-if="loadEnable">
                        <img v-if="loadEnable" style="width: 45px;" :src="iconLoading" alt="Loading" />
                      </div>
                      <div v-else class="d-flex align-items-center">
                          <!-- 
                              "patient_genre": "Sexo",
                              "patient_age": "Idade",
                              "patient_stature": "Estatura",
                              "patient_preference_up": "Preferência superior",
                              "patient_preference_down": "Preferência inferior"
                          -->
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2">{{patient.demographic.name}}</span>
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Sexo -->{{ Lang('patient_genre') }}: {{patient.demographic.gender}}</span>
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Idade-->{{ Lang('patient_age') }}: {{calculateAge(patient.demographic.birthday)}} anos</span>
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Massa-->{{ Lang('patient_mass') }}: {{patient.demographic.weight}} kg</span>
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Estatura-->{{ Lang('patient_stature') }}: {{patient.demographic.height}} cm</span>
                                                                                                                                                                  <!-- Right = Direita | Left = Esquerda | Both = Ambidestro -->
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Peferência superior-->{{ Lang('patient_preference_up') }}: {{ Lang(patient.demographic.upper_limb_dominance) }}</span>
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Peferência inferior-->{{ Lang('patient_preference_down') }}: {{Lang(patient.demographic.lower_limb_dominance)}}</span>
                          <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><!-- Lesão -->{{ Lang('patient_injury') }}: {{patient.demographic.current_injury}}</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
      <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
          <h4 class="mb-5 mb-sm-0 fw-bold">{{ Lang('subtitle_Countermovement_Jump') }}</h4>
      </div>
      <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
          <h6 class="mb-5 mb-sm-0 fw-bold">{{ Lang('titleH1') }}</h6>
      </div>
      <div class="row">
          <div class="col-xl-3 col-sm-6">
            <a href="#/jump_heights_max-graph" @click="loadGraph('jump_heights_max', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('performance','Maximum_Height_cm') }}
                        </span>
                        <div class="d-flex align-items-center">
                          <h3 class="fw-black mb-0 me-10">{{ jump_heights_max_current }}</h3>
                          
                          <div v-if="!(jump_heights_max_perc_variation === 0)" v-html="loadingPercentage(jump_heights_max_perc_variation)"></div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
  
          <div class="col-xl-3 col-sm-6">
            <a href="#/jump_heights_mean-graph" @click="loadGraph('jump_heights_mean', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('performance', 'Average_Height_cm') }}
                        </span>
                        <div class="d-flex align-items-center">
                          <h3 class="fw-black mb-0 me-10">{{ jump_heights_mean_current }}</h3>
                         
                          <div v-if="!(jump_heights_mean_current_perc_variation === 0)" v-html="loadingPercentage(jump_heights_mean_current_perc_variation)"></div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
  
  
          <div class="col-xl-3 col-sm-6">
            <a href="#/peak_powers_max-graph" @click="loadGraph('peak_powers_max', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('performance','PEAK_POWER_W_Kg') }}
                        </span>
                        <div class="d-flex align-items-center">
                          <h3 class="fw-black mb-0 me-10">{{ peak_powers_max }}</h3>
                          
                          <div  v-if="!(peak_powers_max_perc_variation === 0)" v-html="loadingPercentage(peak_powers_max_perc_variation)"></div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
  
          <div class="col-xl-3 col-sm-6">
            <a href="#/peak_powers_mean-graph" @click="loadGraph('peak_powers_mean', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('performance','Average_Peak_Power_W_Kg') }}
                        </span>
                        <div class="d-flex align-items-center">
                          <h3 class="fw-black mb-0 me-10">{{ peak_powers_mean }}</h3>
                         
                          <div v-if="!(peak_powers_mean_perc_variation === 0)" v-html="loadingPercentage(peak_powers_mean_perc_variation)"></div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          
      </div>
      <div class="row">
  
        <div class="col-xl-3 col-sm-6">
          <a href="#/adjusted_RSIs_max-graph" @click="loadGraph('adjusted_RSIs_max', true)" class="link-graph">
            <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
              <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                <div class="d-flex align-items-center">
                  <div class="title ms-15">
                    
                      <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                        {{ Lang('performance', 'Reactive_Strength_Index_RSI') }}
                      </span>
                      <div class="d-flex align-items-center">
                        <h3 class="fw-black mb-0 me-10">{{ adjusted_RSIs_max_current }}</h3>
                       
                        <div v-if="!(adjusted_RSIs_max_perc_variation === 0)" v-html="loadingPercentage(adjusted_RSIs_max_perc_variation)"></div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
  
        <div class="col-xl-3 col-sm-6">
          <a href="#/adjusted_RSIs_mean-graph" @click="loadGraph('adjusted_RSIs_mean', true)" class="link-graph">
            <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
              <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                <div class="d-flex align-items-center">
                  <div class="title ms-15">
                    
                      <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                        {{ Lang('performance','adjusted_RSIs_mean_RSI') }}
                      </span>
                      <div class="d-flex align-items-center">
                        <h3 class="fw-black mb-0 me-10">{{ adjusted_RSIs_mean_current }}</h3>
                        
                        <div v-if="!(adjusted_RSIs_mean_perc_variation === 0)" v-html="loadingPercentage(adjusted_RSIs_mean_perc_variation)"></div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <!-- NOVO BLOCO PARA SPACE DISPLACEMENT GRAPH -->
        <div class="col-xl-3 col-sm-6">
          <a href="#/deslocamento-horizontal" @click="loadGraphFootSpaceDisplacement('body_displacemente', true)" class="link-graph">
            <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
              <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                <div class="d-flex align-items-center">
                  <div class="title ms-15">
                    
                      <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                        <!-- DESLOCAMENTO HORIZONTAL (CM) -->{{ Lang('performance','HORIZONTAL_OFFSET_CM') }}
                      </span>
                      <div class="d-flex align-items-center">
                        <h3 class="fw-black mb-0 me-10">{{ body_displacemente_current }}</h3>
                        
                        <div v-if="!(body_displacemente_perc_variation === 0)" v-html="loadingPercentage(body_displacemente_perc_variation)"></div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
  
      </div>
  
      <p><small>{{ Lang('date_Last_review_on') }} {{showDateLast()}}</small></p>
      
      <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h4 class="mb-5 mb-sm-0 fw-bold">{{Lang('kinematic_box','titleH1')}}</h4>
      </div>
      <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h6 class="mb-5 mb-sm-0 fw-bold">{{Lang('kinematic_box', 'subtitle_1')}}</h6>
      </div>
  
      <div class="row">
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph" @click="loadGraphKine('Hip', 'Ext_Flex', true)" class="link-graph">  
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        
                          <span
                            class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                            {{Lang('kinematic_box','Hip_Flexion_Extension')}}</span>
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                            <h3 class="fw-black mb-0 me-10">{{kinematic_Hip_Ext_Flex_R_propulsive_current}}°</h3>
                          </div>
    
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                            <h3 class="fw-black mb-0 me-10">{{Hip_Ext_Flex_L_propulsive_current}}°</h3>
                          </div>
                        
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph" @click="loadGraphKine('Hip', 'Abd_Add', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        
                          <span
                            class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                            {{ Lang('kinematic_box', 'Hip_Adduction_Abduction') }}</span>
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                            <h3 class="fw-black mb-0 me-10">{{Hip_Abd_Add_R_propulsive_current}}°</h3>
                          </div>
  
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                            <h3 class="fw-black mb-0 me-10">{{Hip_Abd_Add_L_propulsive_current}}°</h3>
                          </div>
                        
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
          
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph" @click="loadGraphKine('Hip', 'RotM_RotL', true)" class="link-graph">  
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        
                          <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                            
                            {{ Lang('kinematic_box', 'Hip_Quadril_Rot_Medial_Rot_Lateral') }}
                          
                          </span>
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                            <h3 class="fw-black mb-0 me-10">{{Hip_RotM_RotL_R_propulsive_current}}°</h3>
                          </div>
    
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                            <h3 class="fw-black mb-0 me-10">{{Hip_RotM_RotL_L_propulsive_current}}°</h3>
                          </div>
                        
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
  
  
  
        </div>
        <div class="row">
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Ext_Flex" @click="loadGraphKine('Knee', 'Ext_Flex', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span
                          class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('kinematic_box','Knee_Flexion_Extension') }}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Ext_Flex_R_propulsive_current}}°</h3>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Ext_Flex_L_propulsive_value}}°</h3>
                        </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
  
  
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Varus_Valgus" @click="loadGraphKine('Knee', 'Varus_Valgus', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span
                          class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('kinematic_box','Knee_Varus_Valgus') }}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Varus_Valgus_R_propulsive_value}}°</h3>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Varus_Valgus_L_propulsive_value}}°</h3>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
            
          </div>
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Valgus_count" @click="loadGraphKine('Knee', 'Valgus_count', true)" class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span
                          class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('kinematic_box','Knee_Valgus') }}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Valgus_count_R_value}}</h3>
                          
                          <div v-if="Knee_Valgus_count_R_percentage != 0 && !isNull(Knee_Valgus_count_R_percentage)" v-html="loadingPercentage(Knee_Valgus_count_R_percentage)"></div>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Valgus_count_L_value}}</h3>
                            
                            <div v-if="Knee_Valgus_count_R_percentage != 0 && !isNull(Knee_Valgus_count_L_percentage)" v-html="loadingPercentage(Knee_Valgus_count_L_percentage)"></div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
  
  
  
  
        </div>
        <div class="row">
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Ankle" @click="loadGraphKine('Ankle', 'Dorsi_PlantarFlex', true)" class="link-graph">
              <div class="card mb-10 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                        <span
                          class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('kinematic_box','Ankle_Dorsiflexion_Plantar_Flexion') }}
                        </span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Ankle_Dorsi_PlantarFlex_R_value}}°</h3>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Ankle_Dorsi_PlantarFlex_L_value}}°</h3>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Inv_Ever" @click="loadGraphKine('Ankle', 'Inv_Ever', true)"  class="link-graph">  
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      
                          <span
                            class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                            {{ Lang('kinematic_box','Ankle_Inversion_Eversion') }}</span>
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                            <h3 class="fw-black mb-0 me-10">{{Ankle_Inv_Ever_R_value}}°</h3>
                          </div>
    
                          <div class="d-flex align-items-center">
                            <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                            <h3 class="fw-black mb-0 me-10">{{Ankle_Inv_Ever_L_value}}°</h3>
                          </div>
    
                    </div>
                    
                  </div>
                </div>
              </div>
            </a>
          </div>
  
  
  
  
        </div>
        <div class="mb-10 d-sm-flex align-items-center justify-content-between letter-spacing">
          <h6 class="mb-5 mb-sm-0 fw-bold">{{Lang('recovery_box', 'subtitle_1')}}</h6>
          <!-- RECOVERY -->
        </div>
        
        <div class="row">
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Ext_Flex-Recovery" @click="loadGraphKineRecovery('Hip', 'Ext_Flex', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{Lang('recovery_box', 'Hip_Flexion_Extension')}}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{kinematic_Hip_Ext_Flex_R_recovery}}°</h3>
                        </div>
  
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{kinematic_Hip_Ext_Flex_L_recovery}}°</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Abd_Add-Recovery" @click="loadGraphKineRecovery('Hip', 'Abd_Add', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15"><span
                          class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{Lang('recovery_box','Hip_Adduction_Abduction')}}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">
                            {{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Abd_Add_R_recovery}}°</h3>
                        </div>
  
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Abd_Add_L_recovery}}°</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-RotM_RotL-Recovery" @click="loadGraphKineRecovery('Hip', 'RotM_RotL', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{Lang('recovery_box', 'Hip_Quadril_Rot_Medial_Rot_Lateral')}}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{RotM_RotL_R_recovery}}°</h3>
                        </div>
  
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{RotM_RotL_L_recovery}}°</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
  
  
  
      </div>
        
      <div class="row">
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Knee_Ext_Flex-Recovery" @click="loadGraphKineRecovery('Knee', 'Ext_Flex', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                  <div class="d-flex align-items-center">
                    <div class="title ms-15">
                      <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                        {{Lang('recovery_box', 'Knee_Flexion_Extension')}}</span>
                      <div class="d-flex align-items-center">
                        <p class="w-black mb-0 me-10" style="padding-right:17px">
                          {{Lang('text_Right')}}
                        </p>
                        <h3 class="fw-black mb-0 me-10">{{Knee_Ext_Flex_R_recovery}}°</h3>
                      </div>
  
                      <div class="d-flex align-items-center">
                        <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                        <h3 class="fw-black mb-0 me-10">{{Knee_Ext_Flex_L_recovery}}°</h3>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </a>
          </div>
  
  
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Knee-Varus_Valgus-Recovery" @click="loadGraphKineRecovery('Knee', 'Varus_Valgus', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('recovery_box', 'Knee_Varus_Valgus') }}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Varus_Valgus_R_recovery}}°</h3>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Knee_Varus_Valgus_L_recovery}}°</h3>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </a>
          </div>
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Knee-Valgus_count-Recovery" @click="loadGraphKineRecovery('Knee', 'Valgus_count', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{Lang('recovery_box', 'Knee_Valgus')}}
                        </span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">
                            {{Knee_Valgus_count_R_recovery}}
                          </h3>
                                              
                          <div v-if="!isNull(Knee_Valgus_count_R_recovery_percentual)" v-html="loadingPercentage(Knee_Valgus_count_R_recovery_percentual)"></div>
                        
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">
                            {{Knee_Valgus_count_L_recovery}}
                          </h3>
                          
                          <div v-if="!isNull(Knee_Valgus_count_L_recovery_percentual)" v-html="loadingPercentage(Knee_Valgus_count_L_recovery_percentual)"></div>
                            
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
  
  
  
        </div>
        
      <div class="row">
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Ankle-Dorsi_PlantarFlex-Recovery" @click="loadGraphKineRecovery('Ankle', 'Dorsi_PlantarFlex', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{Lang('recovery_box', 'Ankle_Dorsiflexion_Plantar_Flexion')}}</span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{Ankle_Dorsi_PlantarFlex_R_recovery}}°</h3>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{Ankle_Dorsi_PlantarFlex_L_recovery}}°</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
          <div class="col-xl-4 col-sm-6">
            <a href="#/kinematics-graph-Ankle-Inv_Ever-Recovery" @click="loadGraphKineRecovery('Ankle', 'Inv_Ever', true)"  class="link-graph">
              <div class="card mb-25 border-0 rounded-0 bg-white stats-box shadow-link">
                
                  <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
                    <div class="d-flex align-items-center">
                      <div class="title ms-15">
                        <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                          {{ Lang('recovery_box','Ankle_Inversion_Eversion') }}
                        </span>
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10" style="padding-right:17px">{{Lang('text_Right')}}</p>
                          <h3 class="fw-black mb-0 me-10">{{ Ankle_Inv_Ever_R_recovery }}°</h3>
                        </div>
    
                        <div class="d-flex align-items-center">
                          <p class="w-black mb-0 me-10">{{Lang('text_Left')}}<span v-html="showSpaceLeft()"></span></p>
                          <h3 class="fw-black mb-0 me-10">{{ Ankle_Inv_Ever_L_recovery }}°</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </a>
          </div>
  
  
  
  
      </div>
    
    </div>

  </div>
  <!-- FIM DOS RESULTADOS DINAMICOS -->
  <div v-else>
    <div class="row">
      <div class="col-xxl-12 col-sm-12">
          <div class="card mb-20 border-0 rounded-0 stats-item bg-white">
              <div class="card-body p-0 p-sm-0 p-md-0 p-lg-0 letter-spacing">
                  <div class="d-flex align-items-center">
                   
                      <span class="d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2"><h4 class="mb-4 mb-sm-0">{{Lang('alert_not_content')}}</h4></span>
                     
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  

  <!-- BLOCOS DOS GRAFICOS -->
  <div v-if="verifyGrapFootDisplacement">
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h5 class="mb-5 mb-sm-0 fw-bold" style="cursor: pointer;" @click="returnDash()"><< {{Lang('bt_go_back')}}</h5> 
    </div>
  
    <FootSpaceDisplacementGraph :graphFoot="graphObjectFootDisplacement" :Lang="Lang('titleGraphFoot')" />
  </div>

  <div v-if="verifyGraphSignalKine">
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h5 class="mb-5 mb-sm-0 fw-bold" style="cursor: pointer;" @click="returnDash()"><< {{Lang('bt_go_back')}}</h5>
    </div>
    <!-- INICIO NAVEGADOR DE SIGNAL_HISTORY -->
    <div class="pagination-area d-md-flex mb-10 justify-content-between align-items-center" style="float: right;">
      <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
              <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous" @click="previousPageDateSignal()">
                      <i class="flaticon-chevron-1"></i>
                  </a>
              </li>
              <li class="">
                  <a class="page-link" href="#" v-html="signalDateHistory"></a>
              </li>
              <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next"  @click="nextPageDateSignal()">
                      <i class="flaticon-chevron"></i>
                  </a>
              </li>
          </ul>
      </nav>
    </div>
    <!-- FIM NAVEGADOR DE SIGNAL_HISTORY -->

    <SignalGraphKinematics :graph="graphObjectSignalKine" :Lang="Lang('titleGraphSignalKine')" />
  </div>

  <div v-else-if="verifyGraph && !verifyGraphKine && !verifyGraphRecovery">
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" v-if="!returnPerformance">
        <h5 class="mb-5 mb-sm-0 fw-bold" style="cursor: pointer;" @click="returnDash()"><< {{Lang('bt_go_back')}}</h5>
    </div>
    <PerformanceGraph :graph="graphObject" :Lang="Lang('titleGraph1')" />
  </div>

  <div v-if="verifyGraphRecovery">
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" v-if="!returnRecovery">
        <h5 class="mb-5 mb-sm-0 fw-bold" style="cursor: pointer;" @click="returnDash()"><< {{Lang('bt_go_back')}}</h5>
    </div>
    <KinematicsGraphDinamic :graphObject="graphObjectKineRecovery" :Lang="Lang('titleGraphRecovery')" />
  </div>

  <div v-if="verifyGraphKine">
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" v-if="!returnhKinematics">
        <h5 class="mb-5 mb-sm-0 fw-bold" style="cursor: pointer;" @click="returnDash()"><< {{Lang('bt_go_back')}}</h5>
    </div>
    <KinematicsGraphDinamic :graphObject="graphObjectKine" :Lang="Lang('titleGraphKine')" />
  </div>

    
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

//controle dos graficos
import jsonGraph from "./jsons/65f99ee1-5990-47ff-8de2-41a0bdea908a.json";
import PerformanceGraph from "@/components/Dashboard/ReportPage/graphics/PerformanceGraph.vue";
import SignalGraphKinematics from "@/components/Dashboard/ReportPage/graphics/SignalGraphKinematics.vue";
import KinematicsGraphDinamic from "@/components/Dashboard/ReportPage/graphics/KinematicsGraphDinamic.vue";
import FootSpaceDisplacementGraph from "@/components/Dashboard/ReportPage/graphics/FootSpaceDisplacementGraph.vue";
//multi idiomas
import {t as lang, switchLanguage} from '@/plugins/loadMultiLanguage';
import { storageService } from "@/store/storageService";
import configs from "@/utils/configs";
import onSystem from "@/utils/getSystemOn";

//teste para navegacao em grafico de Sinais
import Plotly from 'plotly.js-dist-min';

const positiveChangeColorDefault = '#00E396';
const netagativeChangeColorDefault = '#FF4560';

const showReport= (archive_json: string | any) =>{
  //permitir temporariamente carregar somente esse UUID
  let patientsIds = localStorage.getItem('patients_jsons');
  if(patientsIds){
    patientsIds = JSON.parse(patientsIds);
    //console.log('array-patients-api-json',patientsIds?.length);
  }
  
  const patientsUUID: any = patientsIds; 
 
  if(patientsUUID.includes(archive_json)){
      const ApiUrl = configs.urlApiFiles + "/";
      const loadJsonFile = async (fileName: string) => {
        try {
          let folderBucket = await onSystem();
          let patientsS3 = await axios.get(configs.urlApiFiles+'/api/v1/list-files?folder='+folderBucket+'/CMJ5');
          //exibir o registro no bucket s3 pelo UUID passado na endpoint
          patientsS3 = patientsS3.data.find(patient => patient.includes(`__${fileName}.json`));
          // Chama a nova API passando o nome do arquivo na URL
          const response = await axios.get(ApiUrl+`api/v1/get-file?fileName=${patientsS3}&folder=${folderBucket}/CMJ5`);
          //armazena o objeto em localStorage
          storageService.setItem('patient_now', response.data.content);
        } catch (error) {
          console.error('Erro ao carregar o arquivo JSON:', error)
        }
      }
      loadJsonFile(archive_json);
  } else {
    console.log("JSON do Report associado ao Paciente nao encontrado..");

  }
}
//controle de navegacao entre as datas do signal_history
let currentIndex = 0;

export default  defineComponent({
    name: "ReportPage",
    components: {
      PerformanceGraph,
      KinematicsGraphDinamic,
      SignalGraphKinematics,
      FootSpaceDisplacementGraph
    },
    data (){
      return {
        iconLoading: './images/loading.gif',
        loadEnable: false,
        patient: storageService.getItem('patient_now') || {}, //recurso para tornar dinamico o conteudo da pagina
        returnPerformance: false,
        returnRecovery: false,
        returnhKinematics: false,
        verifyGraph: false,
        graphObject: {} as any,
        jump_heights_mean_current: jsonGraph.performance.jump_heights_mean.current,
        jump_heights_mean_current_perc_variation: jsonGraph.performance.jump_heights_mean.perc_variation,
        jump_heights_max_current: jsonGraph.performance.jump_heights_max.current,
        jump_heights_max_perc_variation: jsonGraph.performance.jump_heights_max.perc_variation,
        peak_powers_max: jsonGraph.performance.peak_powers_max.current,
        peak_powers_max_perc_variation: jsonGraph.performance.peak_powers_max.perc_variation,
        peak_powers_mean: jsonGraph.performance.peak_powers_mean.current,
        peak_powers_mean_perc_variation: jsonGraph.performance.peak_powers_mean.perc_variation,
        adjusted_RSIs_max_current: jsonGraph.performance.adjusted_RSIs_max.current,
        adjusted_RSIs_max_perc_variation: jsonGraph.performance.adjusted_RSIs_max.perc_variation,
        adjusted_RSIs_mean_current: jsonGraph.performance.adjusted_RSIs_mean.current,
        adjusted_RSIs_mean_perc_variation: jsonGraph.performance.adjusted_RSIs_mean.perc_variation,
        //novo card
        verifyGrapFootDisplacement: false,
        graphObjectFootDisplacement: {} as any,
        body_displacemente_vectors: jsonGraph.performance.body_displacemente.vectors[0], 
        body_displacemente_mean_vector: jsonGraph.performance.body_displacemente.mean_vector[0], 
        body_displacemente_rightSideLabel: 'Right', 
        body_displacemente_leftSideLabel: 'Left',
        body_displacemente_ylabel: 'Antero-Posterior',
        body_displacemente_xlabel: 'Lateral-Lateral',
        body_displacemente_current: jsonGraph.performance.body_displacemente.current,
        body_displacemente_perc_variation: jsonGraph.performance.body_displacemente.perc_variation,
        classPergentage: (jsonGraph.performance.jump_heights_max.perc_variation < 1)? 'fw-bold text-danger text-badge d-inline-block': 'fw-bold text-success text-badge d-inline-block',
        //kinematic
        verifyGraphKine: false,
        graphObjectKine: {} as any,
        verifyGraphSignalKine: false,
        graphObjectSignalKine: {} as any,
        signalDateHistory: '',
        verifyGraphRecovery: false,
        graphObjectKineRecovery: {} as any,
        kinematic_Hip_Ext_Flex_R_propulsive_current: jsonGraph.kinematic.Hip.Ext_Flex.right.phases.propulsive.values.slice(-1)[0],
        Hip_Ext_Flex_L_propulsive_current: jsonGraph.kinematic.Hip.Ext_Flex.left.phases.propulsive.values.slice(-1)[0],
        Hip_Abd_Add_R_propulsive_current: jsonGraph.kinematic.Hip.Abd_Add.right.phases.propulsive.values.slice(-1)[0],
        Hip_Abd_Add_L_propulsive_current: jsonGraph.kinematic.Hip.Abd_Add.left.phases.propulsive.values.slice(-1)[0],
        Hip_RotM_RotL_R_propulsive_current: jsonGraph.kinematic.Hip.RotM_RotL.right.phases.propulsive.values.slice(-1)[0],
        Hip_RotM_RotL_L_propulsive_current: jsonGraph.kinematic.Hip.RotM_RotL.left.phases.propulsive.values.slice(-1)[0],
        Knee_Ext_Flex_R_propulsive_current: jsonGraph.kinematic.Knee.Ext_Flex.right.phases.propulsive.values.slice(-1)[0],
        Knee_Ext_Flex_L_propulsive_value: jsonGraph.kinematic.Knee.Ext_Flex.left.phases.propulsive.values.slice(-1)[0],
        Knee_Varus_Valgus_R_propulsive_value: jsonGraph.kinematic.Knee.Varus_Valgus.right.phases.propulsive.values.slice(-1)[0],
        Knee_Varus_Valgus_L_propulsive_value: jsonGraph.kinematic.Knee.Varus_Valgus.left.phases.propulsive.values.slice(-1)[0],
        Knee_Valgus_count_R_value: jsonGraph.kinematic.Knee.Valgus_count.right.phases.propulsive.values.slice(-1)[0],
        Knee_Valgus_count_L_value: jsonGraph.kinematic.Knee.Valgus_count.left.phases.propulsive.values.slice(-1)[0],
        Knee_Valgus_count_R_percentage: jsonGraph.kinematic.Knee.Valgus_count.right.phases.propulsive.perc_variation,
        Knee_Valgus_count_L_percentage: jsonGraph.kinematic.Knee.Valgus_count.left.phases.propulsive.perc_variation as any,
        Ankle_Dorsi_PlantarFlex_R_value: jsonGraph.kinematic.Ankle.Dorsi_PlantarFlex.right.phases.propulsive.values.slice(-1)[0],
        Ankle_Dorsi_PlantarFlex_L_value: jsonGraph.kinematic.Ankle.Dorsi_PlantarFlex.left.phases.propulsive.values.slice(-1)[0],
        Ankle_Inv_Ever_R_value: jsonGraph.kinematic.Ankle.Inv_Ever.right.phases.propulsive.values.slice(-1)[0],
        Ankle_Inv_Ever_L_value: jsonGraph.kinematic.Ankle.Inv_Ever.left.phases.propulsive.values.slice(-1)[0],
        //kinemetic recovery
        kinematic_Hip_Ext_Flex_R_recovery: jsonGraph.kinematic.Hip.Ext_Flex.right.phases.recovery.values.slice(-1)[0],
        kinematic_Hip_Ext_Flex_L_recovery: jsonGraph.kinematic.Hip.Ext_Flex.left.phases.recovery.values.slice(-1)[0],
        Abd_Add_R_recovery: jsonGraph.kinematic.Hip.Abd_Add.right.phases.recovery.values.slice(-1)[0],
        Abd_Add_L_recovery: jsonGraph.kinematic.Hip.Abd_Add.left.phases.recovery.values.slice(-1)[0],
        RotM_RotL_R_recovery: jsonGraph.kinematic.Hip.RotM_RotL.right.phases.recovery.values.slice(-1)[0],
        RotM_RotL_L_recovery: jsonGraph.kinematic.Hip.RotM_RotL.left.phases.recovery.values.slice(-1)[0],
        Knee_Ext_Flex_R_recovery: jsonGraph.kinematic.Knee.Ext_Flex.right.phases.recovery.values.slice(-1)[0],
        Knee_Ext_Flex_L_recovery: jsonGraph.kinematic.Knee.Ext_Flex.left.phases.recovery.values.slice(-1)[0],
        Knee_Varus_Valgus_R_recovery: jsonGraph.kinematic.Knee.Varus_Valgus.right.phases.recovery.values.slice(-1)[0],
        Knee_Varus_Valgus_L_recovery: jsonGraph.kinematic.Knee.Varus_Valgus.left.phases.recovery.values.slice(-1)[0],
        Knee_Valgus_count_R_recovery: jsonGraph.kinematic.Knee.Valgus_count.right.phases.recovery.values.slice(-1)[0],
        Knee_Valgus_count_L_recovery: jsonGraph.kinematic.Knee.Valgus_count.left.phases.recovery.values.slice(-1)[0],
        Knee_Valgus_count_R_recovery_percentual: jsonGraph.kinematic.Knee.Valgus_count.right.phases.recovery.perc_variation,
        Knee_Valgus_count_L_recovery_percentual: jsonGraph.kinematic.Knee.Valgus_count.left.phases.recovery.perc_variation,
        Ankle_Dorsi_PlantarFlex_R_recovery: jsonGraph.kinematic.Ankle.Dorsi_PlantarFlex.right.phases.recovery.values.slice(-1)[0],
        Ankle_Dorsi_PlantarFlex_L_recovery: jsonGraph.kinematic.Ankle.Dorsi_PlantarFlex.left.phases.recovery.values.slice(-1)[0],
        Ankle_Inv_Ever_R_recovery: jsonGraph.kinematic.Ankle.Inv_Ever.right.phases.recovery.values.slice(-1)[0],
        Ankle_Inv_Ever_L_recovery: jsonGraph.kinematic.Ankle.Inv_Ever.left.phases.recovery.values.slice(-1)[0],
      }
    },
    watch: {
      patient: {
        deep: true, // Para observar mudanças profundas dentro do objeto patient
        handler(newVal) {
          this.loadEnable = true;
          //console.log('Mudança detectada no localStorage:', newVal);
          this.loadStoragePatient(newVal);
          // Aqui você pode adicionar lógica para tratar mudanças, como atualizar a interface
        }
      }
    },
    mounted(){
      switchLanguage(this.langString);
    
      //novo recurso de dinamica dos pacientes infos
      window.addEventListener('localStorageUpdate', this.handleStorageUpdate);
    },
    beforeUnmount() {
    
      //novo recurso de dinamica dos pacientes infos
      window.removeEventListener('localStorageUpdate', this.handleStorageUpdate);
    },
    methods: {
      loadGraph(varName: string, status: boolean) {
        
        this.verifyGraph = true;
        this.graphObject = {
          dates: this.patient.performance.dates,
          values: this.patient.performance[varName].values,
          varName: varName,
          lowerLimit: this.patient.performance[varName].lower_bound,
          upperLimit: this.patient.performance[varName].upper_bound,
          yLabel: this.patient.performance[varName].ylabel,
          elementId:  'chartPerformance',
          directionChange: this.patient.performance[varName].direction,
          positiveChangeColor: (this.patient.performance[varName].perc_variation < 1)? positiveChangeColorDefault : netagativeChangeColorDefault,
          netagativeChangeColor: (this.patient.performance[varName].perc_variation < 1)?  netagativeChangeColorDefault : positiveChangeColorDefault,
        };
      },
      loadGraphKine(varKey: string, secundaryKey: string, status: boolean) {
        this.verifyGraphKine = true;
        this.verifyGraph = status;
        //ocultar voltar desnecessario
        this.returnhKinematics = true;
        
        this.graphObjectKine = {
          dates: this.patient.performance.dates,
          values_L: this.patient.kinematic[varKey][secundaryKey].left.phases.propulsive.values,
          values_R: this.patient.kinematic[varKey][secundaryKey].right.phases.propulsive.values,
          varName: 'kinematics',
          upperLimit: 0,
          lowerLimit: 0,
          yLabel: this.patient.kinematic[varKey][secundaryKey].ylabel,
          elementId: 'chartCinemathic',
          directionChange: this.patient.kinematic[varKey][secundaryKey].direction,
          positiveChangeColor: positiveChangeColorDefault,
          negativeChangeColor: netagativeChangeColorDefault
        }
        //carregar em conjunto o gafico de sinais, caso nao seja vazio
        if(this.patient.kinematic[varKey][secundaryKey].right.signal.mean &&
          this.patient.kinematic[varKey][secundaryKey].right.signal.upper &&
          this.patient.kinematic[varKey][secundaryKey].right.signal.lower &&
          this.patient.kinematic[varKey][secundaryKey].left.signal.mean &&
          this.patient.kinematic[varKey][secundaryKey].left.signal.upper &&
          this.patient.kinematic[varKey][secundaryKey].left.signal.lower
        ){
          this.loadGraphSignalKine(varKey, secundaryKey);
        } else {
          this.returnhKinematics = false;
        }
      },
      loadGraphKineRecovery(varKey: string, secundaryKey: string, status: boolean) {
        this.verifyGraphRecovery = true;
        //ocultar voltar desnecessario
        this.returnRecovery = true;
        if(this.patient.kinematic[varKey][secundaryKey].left.phases.recovery.values){
          this.verifyGraphRecovery = true;
          this.verifyGraph=true;
          this.graphObjectKineRecovery = {
            dates: this.patient.performance.dates,
            values_L: this.patient.kinematic[varKey][secundaryKey].left.phases.recovery.values as [],
            values_R: this.patient.kinematic[varKey][secundaryKey].right.phases.recovery.values as [],
            varName: 'kinematics2',
            upperLimit: 0,
            lowerLimit: 0,
            yLabel: this.patient.kinematic[varKey][secundaryKey].ylabel,
            elementId: 'chartCinemathic',
            directionChange: this.patient.kinematic[varKey][secundaryKey].direction,
            positiveChangeColor: positiveChangeColorDefault,
            negativeChangeColor: netagativeChangeColorDefault
          }
        }
        //carregar em conjunto o gafico de sinais, caso nao seja vazio
        if(this.patient.kinematic[varKey][secundaryKey].right.signal.mean &&
          this.patient.kinematic[varKey][secundaryKey].right.signal.upper &&
          this.patient.kinematic[varKey][secundaryKey].right.signal.lower &&
          this.patient.kinematic[varKey][secundaryKey].left.signal.mean &&
          this.patient.kinematic[varKey][secundaryKey].left.signal.upper &&
          this.patient.kinematic[varKey][secundaryKey].left.signal.lower
        ){
          this.loadGraphSignalKine(varKey, secundaryKey);
        } else {
          this.returnRecovery = false;
        }
        
      },
      loadGraphSignalKine(varKey: string, secundaryKey: string){
        this.verifyGraphSignalKine = true;
        
        //carregando a primeira data do array de navegacao entre dadas
        this.displayData(varKey,secundaryKey,'right',this.patient.kinematic);
        this.graphObjectSignalKine = {
          right_mean: this.patient.kinematic[varKey][secundaryKey].right.signal.mean, 
          rightUpperBound: this.patient.kinematic[varKey][secundaryKey].right.signal.upper,
          rightLowerBound: this.patient.kinematic[varKey][secundaryKey].right.signal.lower,
          left_mean: this.patient.kinematic[varKey][secundaryKey].left.signal.mean,
          leftUpperBound: this.patient.kinematic[varKey][secundaryKey].left.signal.upper,
          leftLowerBound:  this.patient.kinematic[varKey][secundaryKey].left.signal.lower,
          elementId: 'chartSignalKime',
          yLabel: this.patient.kinematic[varKey][secundaryKey].ylabel
        }
      },
      loadGraphFootSpaceDisplacement(varName: string, status: boolean=true){
        this.verifyGraph = true;
        this.returnPerformance= true;
        //carregar grafico de historico
        this.graphObject = {
          dates: this.patient.performance.dates,
          values: this.patient.performance[varName].values,
          varName: varName,
          lowerLimit: this.patient.performance[varName].lower_bound,
          upperLimit: this.patient.performance[varName].upper_bound,
          yLabel: this.patient.performance[varName].ylabel,
          elementId:  'chartPerformance',
          directionChange: this.patient.performance[varName].direction,
          positiveChangeColor: (this.patient.performance[varName].perc_variation < 1)? positiveChangeColorDefault : netagativeChangeColorDefault,
          netagativeChangeColor: (this.patient.performance[varName].perc_variation < 1)?  netagativeChangeColorDefault : positiveChangeColorDefault,
        };
        this.verifyGrapFootDisplacement = true;
        this.graphObjectFootDisplacement = {
            vectors: this.body_displacemente_vectors, 
            mean_vector: this.body_displacemente_mean_vector, 
            rightSideLabel: 'Right', 
            leftSideLabel: 'Left',
            ylabel: 'Antero-Posterior',
            xlabel: 'Lateral-Lateral'
        };
        //console.log(this.graphObjectFootDisplacement);
        return true;
      },
      returnDash(){
        this.verifyGraph = false;
        this.verifyGraphKine = false;
        this.verifyGraphSignalKine = false;
        this.verifyGraphRecovery = false;
        this.verifyGrapFootDisplacement = false;
        this.returnPerformance= false;
      },
      loadingPercentage(jump_heights_max_perc_variation: number | any){
        if(!this.isNull(jump_heights_max_perc_variation)){
          let iconUpDown = '';
          let classPergentage = (jump_heights_max_perc_variation < 1)? 'fw-bold text-danger text-badge d-inline-block': 'fw-bold text-success text-badge d-inline-block';
          classPergentage = (jump_heights_max_perc_variation === 0)? 'fw-bold text-black text-badge d-inline-block' : classPergentage;
          if(jump_heights_max_perc_variation > 0){
            iconUpDown = '<i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"></i>';
          } else if(jump_heights_max_perc_variation === 0){
            iconUpDown = '<i style="color: black;" class="flaticon-black-circle fs-11 lh-1 position-relative top-1"></i>';
          } else {
            iconUpDown = '<i v-else class="flaticon-down-arrow fs-11 lh-1 position-relative top-1" data-v-98135cde=""></i>';
          }
          return `<span class="${classPergentage}">
                    ${iconUpDown }
                    ${jump_heights_max_perc_variation}% 
                  </span>`;
        }
        return "";
        
      },
      parseDate(dateString: any) {
        let [day, month, year] = dateString.split('/');
        return new Date(year, month - 1, day); // month - 1 porque o mês em JavaScript é zero-based
      },
      showDateLast(){
        const dates = this.patient.performance.dates;

        // Encontrar a data mais recente
        let latestDate = dates
        .map(date => this.parseDate(date))      // Converter as strings para objetos Date
        .reduce((latest, current) =>       // Reduzir ao valor mais recente
          current > latest ? current : latest
        );

        // Converter de volta para o formato "dd/mm/yyyy" se necessário
        let latestDateString = latestDate.toLocaleDateString('pt-BR');
        //console.log(latestDateString);
        return latestDateString;
      },
      isNull(value: any): boolean {
          return value === null;
      },
      Lang(param1: string, param2: string=""){
        return lang(param1, param2);
      },
      showSpaceLeft()
      {
        return (this.langString === 'en' )? "&nbsp; &nbsp; &nbsp; &nbsp;" : "";
      },
      //metodo de leitura dinamica do storageService
      handleStorageUpdate(event) {
        const { key, value } = event.detail;
        if (key === 'patient_now') {
          this.patient = value || {};
        }
      },
      calculateAge(dataNascimento: string) {
        // Separar a data no formato DD/MM/YYYY
        const [dia, mes, ano] = dataNascimento.split('/').map(Number);
        
        // Criar a data de nascimento como um objeto Date
        const dataNasc = new Date(ano, mes - 1, dia);

        // Obter a data atual
        const hoje = new Date();

        // Calcular a diferença de anos
        let idade = hoje.getFullYear() - dataNasc.getFullYear();

        // Ajustar a idade se o aniversário ainda não tiver ocorrido neste ano
        const mesAtual = hoje.getMonth();
        const diaAtual = hoje.getDate();

        if (mesAtual < mes - 1 || (mesAtual === mes - 1 && diaAtual < dia)) {
          idade--;
        }

        return idade;
      },
      loadStoragePatient(patient: any){
      
        this.jump_heights_mean_current= patient.performance.jump_heights_mean.current;
        this.jump_heights_mean_current_perc_variation= patient.performance.jump_heights_mean.perc_variation;
        this.jump_heights_max_current= patient.performance.jump_heights_max.current;
        this.jump_heights_max_perc_variation=  patient.performance.jump_heights_max.perc_variation;
        this.peak_powers_max = patient.performance.peak_powers_max.current;
        this.peak_powers_max_perc_variation = patient.performance.peak_powers_max.perc_variation;
        this.peak_powers_mean= patient.performance.peak_powers_mean.current;
        this.peak_powers_mean_perc_variation= patient.performance.peak_powers_mean.perc_variation;
        this.adjusted_RSIs_max_current= patient.performance.adjusted_RSIs_max.current;
        this.adjusted_RSIs_max_perc_variation= patient.performance.adjusted_RSIs_max.perc_variation;
        this.adjusted_RSIs_mean_current= patient.performance.adjusted_RSIs_mean.current;
        this.adjusted_RSIs_mean_perc_variation= patient.performance.adjusted_RSIs_mean.perc_variation;
        //Foot Space Displacement
        this.body_displacemente_vectors = patient.performance.body_displacemente.vectors[0];
        this.body_displacemente_mean_vector = patient.performance.body_displacemente.mean_vector[0]; 
        this.body_displacemente_rightSideLabel= 'Right'; 
        this.body_displacemente_leftSideLabel =  'Left';
        this.body_displacemente_ylabel = 'Antero-Posterior';
        this.body_displacemente_xlabel = 'Lateral-Lateral';
        this.body_displacemente_current = patient.performance.body_displacemente.current;
        this.body_displacemente_perc_variation = patient.performance.body_displacemente.perc_variation;
        this.classPergentage = (patient.performance.jump_heights_max.perc_variation < 1)? 'fw-bold text-danger text-badge d-inline-block': 'fw-bold text-success text-badge d-inline-block';
        
        //kinematic
        this.kinematic_Hip_Ext_Flex_R_propulsive_current= patient.kinematic.Hip.Ext_Flex.right.phases.propulsive.values.slice(-1)[0];
        this.Hip_Ext_Flex_L_propulsive_current= patient.kinematic.Hip.Ext_Flex.left.phases.propulsive.values.slice(-1)[0];
        this.Hip_Abd_Add_R_propulsive_current= patient.kinematic.Hip.Abd_Add.right.phases.propulsive.values.slice(-1)[0];
        this.Hip_Abd_Add_L_propulsive_current= patient.kinematic.Hip.Abd_Add.left.phases.propulsive.values.slice(-1)[0];
        this.Hip_RotM_RotL_R_propulsive_current= patient.kinematic.Hip.RotM_RotL.right.phases.propulsive.values.slice(-1)[0];
        this.Hip_RotM_RotL_L_propulsive_current= patient.kinematic.Hip.RotM_RotL.left.phases.propulsive.values.slice(-1)[0];
        this.Knee_Ext_Flex_R_propulsive_current= patient.kinematic.Knee.Ext_Flex.right.phases.propulsive.values.slice(-1)[0];
        this.Knee_Ext_Flex_L_propulsive_value= patient.kinematic.Knee.Ext_Flex.left.phases.propulsive.values.slice(-1)[0];
        this.Knee_Varus_Valgus_R_propulsive_value= patient.kinematic.Knee.Varus_Valgus.right.phases.propulsive.values.slice(-1)[0];
        this.Knee_Varus_Valgus_L_propulsive_value= patient.kinematic.Knee.Varus_Valgus.left.phases.propulsive.values.slice(-1)[0];
        this.Knee_Valgus_count_R_value= patient.kinematic.Knee.Valgus_count.right.phases.propulsive.values.slice(-1)[0];
        this.Knee_Valgus_count_L_value= patient.kinematic.Knee.Valgus_count.left.phases.propulsive.values.slice(-1)[0];
        this.Knee_Valgus_count_R_percentage= patient.kinematic.Knee.Valgus_count.right.phases.propulsive.perc_variation;
        this.Knee_Valgus_count_L_percentage= patient.kinematic.Knee.Valgus_count.left.phases.propulsive.perc_variation as any;
        this.Ankle_Dorsi_PlantarFlex_R_value= patient.kinematic.Ankle.Dorsi_PlantarFlex.right.phases.propulsive.values.slice(-1)[0];
        this.Ankle_Dorsi_PlantarFlex_L_value= patient.kinematic.Ankle.Dorsi_PlantarFlex.left.phases.propulsive.values.slice(-1)[0];
        this.Ankle_Inv_Ever_R_value= patient.kinematic.Ankle.Inv_Ever.right.phases.propulsive.values.slice(-1)[0];
        this.Ankle_Inv_Ever_L_value= patient.kinematic.Ankle.Inv_Ever.left.phases.propulsive.values.slice(-1)[0];
       
        //kinemetic recovery
        this.kinematic_Hip_Ext_Flex_R_recovery= patient.kinematic.Hip.Ext_Flex.right.phases.recovery.values.slice(-1)[0];
        this.kinematic_Hip_Ext_Flex_L_recovery= patient.kinematic.Hip.Ext_Flex.left.phases.recovery.values.slice(-1)[0];
        this.Abd_Add_R_recovery= patient.kinematic.Hip.Abd_Add.right.phases.recovery.values.slice(-1)[0];
        this.Abd_Add_L_recovery= patient.kinematic.Hip.Abd_Add.left.phases.recovery.values.slice(-1)[0];
        this.RotM_RotL_R_recovery= patient.kinematic.Hip.RotM_RotL.right.phases.recovery.values.slice(-1)[0];
        this.RotM_RotL_L_recovery= patient.kinematic.Hip.RotM_RotL.left.phases.recovery.values.slice(-1)[0];
        this.Knee_Ext_Flex_R_recovery= patient.kinematic.Knee.Ext_Flex.right.phases.recovery.values.slice(-1)[0];
        this.Knee_Ext_Flex_L_recovery= patient.kinematic.Knee.Ext_Flex.left.phases.recovery.values.slice(-1)[0];
        this.Knee_Varus_Valgus_R_recovery= patient.kinematic.Knee.Varus_Valgus.right.phases.recovery.values.slice(-1)[0];
        this.Knee_Varus_Valgus_L_recovery= patient.kinematic.Knee.Varus_Valgus.left.phases.recovery.values.slice(-1)[0];
        this.Knee_Valgus_count_R_recovery= patient.kinematic.Knee.Valgus_count.right.phases.recovery.values.slice(-1)[0];
        this.Knee_Valgus_count_L_recovery= patient.kinematic.Knee.Valgus_count.left.phases.recovery.values.slice(-1)[0];
        this.Knee_Valgus_count_R_recovery_percentual= patient.kinematic.Knee.Valgus_count.right.phases.recovery.perc_variation;
        this.Knee_Valgus_count_L_recovery_percentual= patient.kinematic.Knee.Valgus_count.left.phases.recovery.perc_variation;
        this.Ankle_Dorsi_PlantarFlex_R_recovery= patient.kinematic.Ankle.Dorsi_PlantarFlex.right.phases.recovery.values.slice(-1)[0];
        this.Ankle_Dorsi_PlantarFlex_L_recovery= patient.kinematic.Ankle.Dorsi_PlantarFlex.left.phases.recovery.values.slice(-1)[0];
        this.Ankle_Inv_Ever_R_recovery= patient.kinematic.Ankle.Inv_Ever.right.phases.recovery.values.slice(-1)[0];
        this.Ankle_Inv_Ever_L_recovery= patient.kinematic.Ankle.Inv_Ever.left.phases.recovery.values.slice(-1)[0];
        setTimeout(() => {
          this.loadEnable = false;  
        }, 500);
        
      },
      displayData(varKey: string,secundaryKey: string,direction: string,signalHistory: any, index: number=0) {
        const currentDate = Object.keys(signalHistory[varKey][secundaryKey][direction].signal_history[index])[0];
        const currentData = signalHistory[varKey][secundaryKey][direction].signal_history[index][currentDate];
        
        storageService.setItem('data_navigation', {
          "varKey": varKey, 
          "secundaryKey": secundaryKey,
          "direction": direction,
          "signalHistory": signalHistory,
          "currentIndex": index,
          "dateKey": currentDate
        });
        // Exibir a data atual no elemento de texto
        this.signalDateHistory = currentDate;

            

        // Exibir os dados no console
        /*
        console.log("Data:", currentDate);
        console.log("Mean:", currentData.mean);
        console.log("Upper:", currentData.upper);
        console.log("Lower:", currentData.lower);
        */
      },
      nextPageDateSignal() {
        let dataNavigation = storageService.getItem('data_navigation');
        let index = dataNavigation.currentIndex;
        if (index < dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey][dataNavigation.direction].signal_history.length - 1) {
          index++;
          this.displayData(dataNavigation.varKey, dataNavigation.secundaryKey,dataNavigation.direction,dataNavigation.signalHistory, index );
          
        }

        //recarregar infos do grafico de sinais
        const contentDiv: any = document.getElementById('chartSignalKime');
        if(contentDiv){
          contentDiv.innerHTML = "";
        }
        this.generatePlotReport2signals(
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].right.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].mean, 
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].right.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].upper,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].right.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].lower,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].left.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].mean,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].left.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].upper,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].left.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].lower,
          'chartSignalKime',
        );
      },
      previousPageDateSignal() {
        let dataNavigation = storageService.getItem('data_navigation');
        let index = dataNavigation.currentIndex;
        if (index > 0) {
          index--;
          this.displayData(dataNavigation.varKey, dataNavigation.secundaryKey,dataNavigation.direction,dataNavigation.signalHistory, index );
        }
        //recarregar grafico de signals
        //recarregar infos do grafico de sinais
        const contentDiv: any = document.getElementById('chartSignalKime');
        if(contentDiv){
          contentDiv.innerHTML = "";
        }
        this.generatePlotReport2signals(
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].right.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].mean, 
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].right.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].upper,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].right.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].lower,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].left.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].mean,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].left.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].upper,
          dataNavigation.signalHistory[dataNavigation.varKey][dataNavigation.secundaryKey].left.signal_history[dataNavigation.currentIndex][dataNavigation.dateKey].lower,
          'chartSignalKime',
        );
      },
      generatePlotReport2signals(
            right_mean: any, 
            rightUpperBound: any, 
            rightLowerBound: any,
            left_mean: any, 
            leftUpperBound: any, 
            leftLowerBound: any,
            elementId: string) {

            /**
             * Generates a Plotly graph with upper and lower bounds for two signals, including event markers and custom styling.
             *
             * @param {Array} right_mean - Array of mean values for the right signal.
             * @param {Array} rightUpperBound - Array of upper bound values for the right signal.
             * @param {Array} rightLowerBound - Array of lower bound values for the right signal.
             * @param {Array} left_mean - Array of mean values for the left signal.
             * @param {Array} leftUpperBound - Array of upper bound values for the left signal.
             * @param {Array} leftLowerBound - Array of lower bound values for the left signal.
             * @param {Array} events - Array of event positions on the x-axis.
             * @param {string} title - The title of the plot.
             *
             * @description This function creates a Plotly graph that visualizes two signals (right and left) with their respective 
             * upper and lower bounds. The graph also includes event markers and a neutral dashed line. The graph is rendered 
             * within a specified container and adapts to screen resizing.
             */


            // Generate an x-axis with 100 points
            // Gera o eixo x do gráfico com 100 ponts 
            const x = Array.from({ length: 101 }, (_, i) => i);

            // Create traces for the right signal
            // Curva Direita Média
            const trace1 = {
                x: x,
                y: right_mean,
                mode: 'lines',
                name: 'Right',
                line: { color: 'blue' }
            };

            // Create traces for the left signal
            // Curva Esquerda Média
            const trace2 = {
                x: x,
                y: left_mean,
                mode: 'lines',
                name: 'Left',
                line: { color: 'red' }
            };

            // Create traces for the upper and lower bounds of the right signal
            // Curva da desvio padrão - área achurada - Direita - Superior
            const trace3 = {
                x: x,
                y: rightUpperBound,
                mode: 'lines',
                name: 'Right Upper Bound',
                line: { color: "#444", width: 0 },
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            // Curva da desvio padrão - área achurada - Direita - Inferior
            const trace4 = {
                x: x,
                y: rightLowerBound,
                mode: 'lines',
                name: 'Right Lower Bound',
                line: { color: "#444", width: 0 },
                fillcolor: 'rgba(68, 68, 255, 0.1)',
                fill: 'tonexty',
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            // Create traces for the upper and lower bounds of the left signal
            // Curva da desvio padrão - área achurada - Esquerda - Superior
            const trace5 = {
                x: x,
                y: leftUpperBound,
                mode: 'lines',
                name: 'Left Upper Bound',
                line: { color: "#444", width: 0 },
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            // Curva da desvio padrão - área achurada - Esquerda - Inferior
            const trace6 = {
                x: x,
                y: leftLowerBound,
                mode: 'lines',
                name: 'Left Lower Bound',
                line: { color: "#444", width: 0 },
                fillcolor: 'rgba(255, 68, 68, 0.1)',
                fill: 'tonexty',
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            /////////////////////////////////////////////////////////////////////
            // Calculate the max and min points to adjust the ylim
            // Calcula o max e min para determinar o range do eixo y
            const maxPoint = Math.max(...rightUpperBound, ...leftUpperBound);
            const minPoint = Math.min(...rightLowerBound, ...leftLowerBound);
            const amplitude = Math.abs(maxPoint - minPoint);

            let yMaxPoint, yMinPoint;

            if (amplitude / 2 > 0) {
                yMaxPoint = maxPoint + amplitude * 0.2;
                yMinPoint = minPoint - amplitude * 0.2;
            } else {
                yMaxPoint = maxPoint - amplitude * 0.2;
                yMinPoint = minPoint + amplitude * 0.2;
            }

            const amplitudeNew = yMaxPoint - yMinPoint;
            const annotationPoint = amplitudeNew / 2 > 0 ? yMinPoint + amplitudeNew * 0.05 : yMinPoint - amplitudeNew * 0.05;
            /////////////////////////////////////////////////////////////////////

            // Create the figure
            const fig = {
                data: [trace1, trace2, trace3, trace4, trace5, trace6],
                layout: {
                    xaxis: { title: '% of cycle', tickvals: [0, 25, 50, 75, 99], ticktext: ['0', '25', '50', '75', '100'], range: [0, 99] },
                    yaxis: { title: 'Angle (°)', range: [yMinPoint, yMaxPoint] },
                    showlegend: true,
                    legend: { x: 0.8, y: 0.01, bgcolor: 'rgba(255, 255, 255, 0)' },
                    autosize: true,
                    // width: 600,
                    // height: 600,
                    // title: { text: title, x: 0.5, xanchor: 'center' },
                    plot_bgcolor: '#f9f9f9',
                    margin: { l: 50, r: 20, t: 35, b: 40 },
                    responsive: true,
                    images: {}
                },

                shapes: {
                    type: "line",
                    x0: 0,
                    x1: 100,
                    y0: 0,
                    y1: 0,
                    line: { color: "rgba(0, 0, 0, 1)", width: 0.5, dash: 'dash' }
                },

            };

            // Add logo to the plot
            fig.layout.images = [{
                source: "https://raw.githubusercontent.com/vicossich/mk3d/main/mk_logo.png",
                xref: "paper", yref: "paper",
                x: 0.95, y: 0.9,
                sizex: 0.15, sizey: 0.15,
                xanchor: "center",
                yanchor: "middle"
            }];

            // Config object for Plotly
            const config = {
                modeBarButtonsToRemove: [
                    'zoom2d', 'pan2d',
                    'select2d', 'lasso2d', 'hoverClosestCartesian',
                    'hoverCompareCartesian', 'toggleSpikelines'
                ]
            };

            // Render the figure
            Plotly.newPlot(elementId, fig.data, fig.layout, config);

            // Resolve resposividade do gráfico
            window.onresize = function () {

                const elementIdTag = document.querySelector("#" + elementId);

                Plotly.relayout(elementIdTag, {
                    'xaxis.autorange': true,
                    'yaxis.autorange': true
                });
            };

      },
    },
    setup(props, { expose }) {
      const langString: any = (localStorage.getItem('lang_var') != null)? localStorage.getItem('lang_var') : 'pt';

      //recurso de observar a mudanda de UUID do paciente ====
      const route = useRoute(); // Obtém o objeto de rota
      const reportId = ref<string | null>(null);

      // Função para atualizar o reportId com base na query string
      const updateReportId = () => {
        reportId.value = route.query.report as string || null;
      };

      // Observa mudanças na query string
      watch(() => route.query.report, (newReportId, oldReportId) => {
        // Atualiza o reportId e pode fazer outras ações se necessário
        reportId.value = newReportId as string || null;
        //console.log('Report ID changed from', oldReportId, 'to', newReportId);
        //carregar o JSON do Paciente escolhido no menu
        showReport(newReportId);
      }, { immediate: true });
      //======================================================

      // Observar mudanças em 'inputText'
      watch(langString, (newValue, oldValue) => {
        switchLanguage(newValue);
      });

      return {
        langString
      };
    },
})
</script>
<style scoped>
.link-graph {
  list-style: none;
  text-decoration: none;
}
.shadow-link:hover{
  /* background-color: #2121fd50; */
  box-shadow: 3px 3px 3px 3px #d9d9d9;
}
</style>