<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body pt-30 ps-30 pe-30 pb-5">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Gallery - 3</h5>
      <div class="row gallery-list">
        <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery1.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery2.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery9.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-6 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery5.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-6 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery4.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery6.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery7.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery8.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
        <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
          <img
            src="../../../assets/images/gallery/gallery3.jpg"
            class="rounded-1"
            alt="gallery-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryThree",
};
</script>