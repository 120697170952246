<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <p style="text-align: center">
            <img src="https://raw.githubusercontent.com/vicossich/mk3d/main/mk_logo.png" width="40%" alt="Logo MokApp">
          </p>
          <h4 class="text-black fw-bold mb-0 text-center">
            Create Your Account
          </h4>
          <form>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Name</label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Jacob Adam"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email Address
              </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. adam127704@gmail.com"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Password
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Confirm Password
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
              />
            </div>
            <div
              class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1 mb-15 mb-md-20"
            >
              <input
                class="form-check-input shadow-none"
                type="checkbox"
                id="remember-me"
              />
              <label class="form-check-label" for="remember-me">
                I accept All
                <a href="#" class="text-decoration-none">Terms & Conditions</a>
              </label>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
            >
              Register Now
            </button>
            <span
              class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16"
            >
              Already Have An Account?
            </span>
            <router-link
              to="/login"
              class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
            >
              Log In Now
            </router-link>
            <span
              class="d-block or text-muted text-center mt-15 mb-15 mt-md-20 mb-md-20 fs-md-15 fs-lg-16 position-relative z-1 lh-1"
            >
              <span class="d-inline-block bg-white">Or</span>
            </span>
            <ul class="socials ps-0 mb-0 list-unstyled text-center">
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative facebook border-0 p-0"
                >
                  <i class="ph-fill ph-facebook-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative google border-0 p-0"
                >
                  <i class="ph-bold ph-google-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative twitter border-0 p-0"
                >
                  <i class="ph-fill ph-twitter-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative linkedin border-0 p-0"
                >
                  <i class="ph-fill ph-linkedin-logo"></i>
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { auth } from '../../../firebase/firebase.ts';
/* eslint-disable */
export default {
  name: "RegisterContent",
  data() {
      return {
          UserOnline: {},
      }
  },
  created() {
    const store = useStore();
    console.log(store);
    if(store && 'getters' in store && store?.getters !== undefined){
      this.UserOnline = store.getters.user.data;
    }
  }
};

document.body.classList.remove("sidebar-hide");
document.body.classList.add("sidebar-hide");
document.body.classList.remove("header-hide");
document.body.classList.add("header-hide");
</script>
<style>
/*
  .sidebar-area{
    display: none;
  }
  .header-area{
    display: none;
  }
  footer{
    display: none;
  }
     */
  .bread-camps{
    display: none !important;
  }
   
</style>