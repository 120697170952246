const onSystem = async () => {
    let on_system: any = localStorage.getItem('on_system');
    if(on_system){
      on_system = atob(on_system);
      on_system = JSON.parse(on_system);
      let systemname = on_system.name.replace(/-/g, '').replace(/\s|\/+/g, '_').toUpperCase();
      systemname = systemname.replace(/__/g, '_')
      
      
      return on_system.id;
    }
  }

  export default onSystem;