import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_4 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_5 = { id: "leafletjs-map-panes" }
const _hoisted_6 = { class: "col-lg-6" }
const _hoisted_7 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_8 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_9 = { id: "leafletjs-map-choropleth" }
const _hoisted_10 = { class: "col-lg-12" }
const _hoisted_11 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_12 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_13 = { id: "leafletjs-map-layers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_l_map, {
              ref: "map",
              zoom: 4,
              center: [47.41322, -1.219482]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l_tile_layer, {
                  url: "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png",
                  "layer-type": "base",
                  name: "OpenStreetMap"
                }),
                _createVNode(_component_l_tile_layer, {
                  url: "http://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png",
                  "layer-type": "base",
                  name: "OpenStreetMap"
                })
              ]),
              _: 1
            }, 512)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_l_map, {
              ref: "map",
              zoom: 4,
              center: [47.41322, -1.219482]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l_tile_layer, {
                  url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                  "layer-type": "base",
                  name: "OpenStreetMap"
                })
              ]),
              _: 1
            }, 512)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_l_map, {
              ref: "map",
              zoom: 10,
              center: [39.73, -104.99]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l_tile_layer, {
                  url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                  "layer-type": "base",
                  name: "OpenStreetMap"
                })
              ]),
              _: 1
            }, 512)
          ])
        ])
      ])
    ])
  ]))
}