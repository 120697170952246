import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7" }
const _hoisted_3 = { class: "col-xxl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_ProfileContent = _resolveComponent("ProfileContent")!
  const _component_ToDoList = _resolveComponent("ToDoList")!
  const _component_RecentActivity = _resolveComponent("RecentActivity")!
  const _component_WorkingSchedule = _resolveComponent("WorkingSchedule")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Profile" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_WhatHappening),
        _createVNode(_component_ProfileContent),
        _createVNode(_component_ToDoList)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RecentActivity),
        _createVNode(_component_WorkingSchedule)
      ])
    ])
  ], 64))
}