import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content d-flex flex-column transition overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.userVerify)
      ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.userVerify)
      ? (_openBlock(), _createBlock(_component_MainSidebar, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      (_ctx.userVerify)
        ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}