<template>
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h4 class="mb-5 mb-sm-0 fw-bold">{{ Lang}}<!--  Foot Space Displacement Graph --></h4>

    </div>
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <small>{{ graphFoot.ylabel }}</small>
    </div>
    <div class="card">
        <div id="chartFootSpaceDisplacement" style="width: 100%; height: 600px;" class="chart">

        </div>
    </div>
</template>
<script lang="ts" type="typescript">
import { defineComponent, PropType } from "vue";

import Plotly from 'plotly.js-dist-min';

export default defineComponent({
    name: "FootSpaceDisplacementGraph",
    mounted() {
        this.footSpaceDisplacementGraph(
            this.graphFoot.vectors, 
            this.graphFoot.mean_vector, 
            this.graphFoot.rightSideLabel, 
            this.graphFoot.leftSideLabel,
            this.graphFoot.ylabel,
            this.graphFoot.xlabel
        );
    },
    /*
    props: {
        graphFoot: {
            type: Object as PropType<{
                vectors: any; 
                mean_vector: any; 
                rightSideLabel?: string; 
                leftSideLabel?: string;
                ylabel?: string;
                xlabel?: string;
            }>,
            required: true,
        },
        Lang: {
            type: String
        },
    },
    */
    props: {
        graphFoot: {
            type: Object as PropType<{
                vectors: any; 
                mean_vector: any; 
                rightSideLabel?: string; 
                leftSideLabel?: string;
                ylabel?: string;
                xlabel?: string;
            }>,
            required: true,
        },
        Lang: {
            type: String
        },
    },
    methods: {

        footSpaceDisplacementGraph(
            vectors, 
            mean_vector, 
            rightSideLabel = 'Right', 
            leftSideLabel = 'Left', 
            ylabel = 'Antero-Posterior', 
            xlabel = 'Lateral-Lateral') {
            let traces: [] | any = [];
            let Xs: [] | any = [];
            let Ys: [] | any = [];

            
            // Create traces for each vector
            vectors.forEach((vector: string[] | any, index) => {
                let x1 = vector[0].x;
                let y1 = vector[0].y;
                let x2 = vector[1].x;
                let y2 = vector[1].y;

                Xs.push(x1, x2);
                Ys.push(y1, y2);

                traces.push({
                    x: [x1, x2],
                    y: [y1, y2],
                    mode: 'lines+markers',
                    line: { color: '#5CB0FF', width: 4 },
                    marker: {
                        size: [1, 30],
                        color: '#5CB0FF',
                        symbol: ['square', 'square'],
                    },
                    showlegend: true,
                    name: `Rep ${index + 1}`
                });

                // Add text annotations inside the squares
                traces.push({
                    x: [x2],
                    y: [y2],
                    mode: 'text',
                    text: [(index + 1).toString()],
                    textposition: 'middle center',
                    textfont: {
                        size: 15,
                        color: '#ffffff'

                    },
                    showlegend: false,
                    hoverinfo: 'none' 
                });
            });

            // Calculate mean vector components
            const meanX: any = Math.round((mean_vector.magnitude * Math.cos(mean_vector.direction_radians)) * 10) / 10;
            const meanY: any = Math.round((mean_vector.magnitude * Math.sin(mean_vector.direction_radians)) * 10) / 10;


            // Create a trace for the mean vector, similar to blue vectors but in red
            traces.push({
                x: [0, meanX],
                y: [0, meanY],
                mode: 'lines+markers',
                line: { color: 'red', width: 3 },
                marker: {
                    size: [1,10],
                    color: 'red',
                    symbol: ['circle','circle'],
                    line: { color: '#ffffff', width: 2 }
                },
                name: `mean`,
                showlegend: true
            });


            Xs.push(meanX);
            Ys.push(meanY);

            // Determine the maximum point for axis limits
            const maxPoint = 1.2 * Math.max(...Xs.map(Math.abs), ...Ys.map(Math.abs));

            // Define layout settings
            const layout = {
                xaxis: {
                    title: xlabel + ' (cm)',
                    range: [-maxPoint, maxPoint],
                    zeroline: true,
                    showgrid: true,
                    zerolinewidth: 1,
                    zerolinecolor: '#cccccc'
                },
                yaxis: {
                    title: ylabel + ' (cm)',
                    range: [maxPoint, -maxPoint], // Inverted y-axis
                    zeroline: true,
                    showgrid: true,
                    zerolinewidth: 1,
                    zerolinecolor: '#cccccc'
                },
                shapes: [
                    {
                        type: 'line',
                        x0: -maxPoint,
                        x1: maxPoint,
                        y0: 0,
                        y1: 0,
                        line: { color: '#cccccc', width: 1 }
                    },
                    {
                        type: 'line',
                        x0: 0,
                        x1: 0,
                        y0: -maxPoint,
                        y1: maxPoint,
                        line: { color: '#cccccc', width: 1 }
                    }],

                images: [{
                    source: "https://raw.githubusercontent.com/vicossich/mk3d/main/mk_logo.png",
                    xref: "paper", yref: "paper",
                    x: 0.90, y: 0.9,
                    sizex: 0.15, sizey: 0.15,
                    xanchor: "center",
                    yanchor: "middle"}],


                annotations: [
                    { x: 0, y: -0.9 * maxPoint, text: 'Posterior', showarrow: false, font: { size: 12, color: 'gray' } },
                    { x: 0, y: 0.9 * maxPoint, text: 'Anterior', showarrow: false, font: { size: 12, color: 'gray' } },
                    { x: -0.8 * maxPoint, y: 0, text: rightSideLabel, showarrow: false, font: { size: 12, color: 'gray' }, xanchor: 'right' },
                    { x: 0.8 * maxPoint, y: 0, text: leftSideLabel, showarrow: false, font: { size: 12, color: 'gray' }, xanchor: 'left' }
                ],

                // width: 600,
                // height: 600,
                autosize: true,
                showlegend: true,
                legend: { x: 0.8, y: 0.01, bgcolor: 'rgba(255, 255, 255, 0)' },
                plot_bgcolor: '#f9f9f9',
                margin: { l: 50, r: 50, t: 25, b: 40 },
            };

            // Render the plot using Plotly.js
            Plotly.newPlot('chartFootSpaceDisplacement', traces, layout);
        },
    },
});
</script>
<style scoped>
.card {
    padding: 10px;
    background: #fff;
    margin-bottom: 20px;
}
.chart {
    width: 100%;
    height: 50%;
    
}
</style>