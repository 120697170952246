import { ref} from 'vue';
import en from '@/locales/en.json';
import pt from '@/locales/pt.json';

const language = ref('en');
const messages = ref({ en, pt });
const switchLanguage = (lang: string | any) => {
    language.value = lang;
};
const t = (key: string, key2: string="") => {
   
      if(key2 !== ""){
        return messages.value[language.value][key][key2];
      }
      return messages.value[language.value][key];
}

export { t,switchLanguage }