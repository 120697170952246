<template>
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h4 class="mb-5 mb-sm-0 fw-bold">{{ Lang}}</h4>

    </div>
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h4 class="mb-5 mb-sm-0 fw-bold">{{ graph.yLabel }}</h4>
    </div>
    <div class="card">
        <div id="chartSignalKime" class="chart">

        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import Plotly from 'plotly.js-dist-min';
/* eslint-disable */
export default defineComponent({
    name: "SignalGraphKinematics",
    mounted() {
        this.generatePlotReport2signals(
            this.graph.right_mean,
            this.graph.rightUpperBound,
            this.graph.rightLowerBound,
            this.graph.left_mean,
            this.graph.leftUpperBound,
            this.graph.leftLowerBound,
            'chartSignalKime'
        );
    },
    props: {
        graph: {
            type: Object as PropType<{
                right_mean: any; 
                rightUpperBound: any; 
                rightLowerBound: any;
                left_mean: any;
                leftUpperBound: any; 
                leftLowerBound: any;
                elementId: string;
                yLabel: string;
            }>,
            required: true,
        },
        Lang: {
            type: String
        },
    },
    methods: {
        generatePlotReport2signals(
            right_mean: any, 
            rightUpperBound: any, 
            rightLowerBound: any,
            left_mean: any, 
            leftUpperBound: any, 
            leftLowerBound: any,
            elementId: string) {

            /**
             * Generates a Plotly graph with upper and lower bounds for two signals, including event markers and custom styling.
             *
             * @param {Array} right_mean - Array of mean values for the right signal.
             * @param {Array} rightUpperBound - Array of upper bound values for the right signal.
             * @param {Array} rightLowerBound - Array of lower bound values for the right signal.
             * @param {Array} left_mean - Array of mean values for the left signal.
             * @param {Array} leftUpperBound - Array of upper bound values for the left signal.
             * @param {Array} leftLowerBound - Array of lower bound values for the left signal.
             * @param {Array} events - Array of event positions on the x-axis.
             * @param {string} title - The title of the plot.
             *
             * @description This function creates a Plotly graph that visualizes two signals (right and left) with their respective 
             * upper and lower bounds. The graph also includes event markers and a neutral dashed line. The graph is rendered 
             * within a specified container and adapts to screen resizing.
             */


            // Generate an x-axis with 100 points
            // Gera o eixo x do gráfico com 100 ponts 
            const x = Array.from({ length: 101 }, (_, i) => i);

            // Create traces for the right signal
            // Curva Direita Média
            const trace1 = {
                x: x,
                y: right_mean,
                mode: 'lines',
                name: 'Right',
                line: { color: 'blue' }
            };

            // Create traces for the left signal
            // Curva Esquerda Média
            const trace2 = {
                x: x,
                y: left_mean,
                mode: 'lines',
                name: 'Left',
                line: { color: 'red' }
            };

            // Create traces for the upper and lower bounds of the right signal
            // Curva da desvio padrão - área achurada - Direita - Superior
            const trace3 = {
                x: x,
                y: rightUpperBound,
                mode: 'lines',
                name: 'Right Upper Bound',
                line: { color: "#444", width: 0 },
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            // Curva da desvio padrão - área achurada - Direita - Inferior
            const trace4 = {
                x: x,
                y: rightLowerBound,
                mode: 'lines',
                name: 'Right Lower Bound',
                line: { color: "#444", width: 0 },
                fillcolor: 'rgba(68, 68, 255, 0.1)',
                fill: 'tonexty',
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            // Create traces for the upper and lower bounds of the left signal
            // Curva da desvio padrão - área achurada - Esquerda - Superior
            const trace5 = {
                x: x,
                y: leftUpperBound,
                mode: 'lines',
                name: 'Left Upper Bound',
                line: { color: "#444", width: 0 },
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            // Curva da desvio padrão - área achurada - Esquerda - Inferior
            const trace6 = {
                x: x,
                y: leftLowerBound,
                mode: 'lines',
                name: 'Left Lower Bound',
                line: { color: "#444", width: 0 },
                fillcolor: 'rgba(255, 68, 68, 0.1)',
                fill: 'tonexty',
                showlegend: false,
                hoverinfo: 'none'  // Disable tooltips
            };

            /////////////////////////////////////////////////////////////////////
            // Calculate the max and min points to adjust the ylim
            // Calcula o max e min para determinar o range do eixo y
            const maxPoint = Math.max(...rightUpperBound, ...leftUpperBound);
            const minPoint = Math.min(...rightLowerBound, ...leftLowerBound);
            const amplitude = Math.abs(maxPoint - minPoint);

            let yMaxPoint, yMinPoint;

            if (amplitude / 2 > 0) {
                yMaxPoint = maxPoint + amplitude * 0.2;
                yMinPoint = minPoint - amplitude * 0.2;
            } else {
                yMaxPoint = maxPoint - amplitude * 0.2;
                yMinPoint = minPoint + amplitude * 0.2;
            }

            const amplitudeNew = yMaxPoint - yMinPoint;
            const annotationPoint = amplitudeNew / 2 > 0 ? yMinPoint + amplitudeNew * 0.05 : yMinPoint - amplitudeNew * 0.05;
            /////////////////////////////////////////////////////////////////////

            // Create the figure
            const fig = {
                data: [trace1, trace2, trace3, trace4, trace5, trace6],
                layout: {
                    xaxis: { title: '% of cycle', tickvals: [0, 25, 50, 75, 99], ticktext: ['0', '25', '50', '75', '100'], range: [0, 99] },
                    yaxis: { title: 'Angle (°)', range: [yMinPoint, yMaxPoint] },
                    showlegend: true,
                    legend: { x: 0.8, y: 0.01, bgcolor: 'rgba(255, 255, 255, 0)' },
                    autosize: true,
                    // width: 600,
                    // height: 600,
                    // title: { text: title, x: 0.5, xanchor: 'center' },
                    plot_bgcolor: '#f9f9f9',
                    margin: { l: 50, r: 20, t: 35, b: 40 },
                    responsive: true,
                    images: {}
                },

                shapes: {
                    type: "line",
                    x0: 0,
                    x1: 100,
                    y0: 0,
                    y1: 0,
                    line: { color: "rgba(0, 0, 0, 1)", width: 0.5, dash: 'dash' }
                },

            };

            // Add logo to the plot
            fig.layout.images = [{
                source: "https://raw.githubusercontent.com/vicossich/mk3d/main/mk_logo.png",
                xref: "paper", yref: "paper",
                x: 0.95, y: 0.9,
                sizex: 0.15, sizey: 0.15,
                xanchor: "center",
                yanchor: "middle"
            }];

            // Config object for Plotly
            const config = {
                modeBarButtonsToRemove: [
                    'zoom2d', 'pan2d',
                    'select2d', 'lasso2d', 'hoverClosestCartesian',
                    'hoverCompareCartesian', 'toggleSpikelines'
                ]
            };

            // Render the figure
            Plotly.newPlot(elementId, fig.data, fig.layout, config);

            // Resolve resposividade do gráfico
            window.onresize = function () {

                const elementIdTag = document.querySelector("#" + elementId);

                Plotly.relayout(elementIdTag, {
                    'xaxis.autorange': true,
                    'yaxis.autorange': true
                });
            };

        }
    },
});

</script>
<style>
body {
    background: #f2f1f9;
}

.card {
    padding: 10px;
    background: #fff;
    margin-bottom: 20px;
}

.chart {
    width: 100%;
    height: 400px;
    
}
</style>