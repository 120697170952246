<template>
  <div class="row">
    <div class="col-md-6 col-xxl-6 col-xxxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing projects-teams-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini1.jpg"
                class="rounded-1"
                width="60"
                alt="project"
              />
              <div class="ms-15">
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mb-8 mb-md-10 text-black"
                >
                  UI/UX Design Team
                </h5>
                <span class="d-block text-muted">
                  Handling
                  <span class="fw-bold">4</span>
                  Projects
                </span>
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                  >
                    <i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                  >
                    <i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                  >
                    <i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p
            class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16"
          >
            This project combiles all the data and backend information to the
            frontend and also design should be appealing.
          </p>
          <ul class="info ps-0 mb-15 list-unstyled">
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
              >
                TEAM LEAD:
              </span>
              <div
                class="user d-flex align-items-center text-secondary fw-semibold"
              >
                <img
                  src="../../../assets/images/user/user1.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle me-8"
                  alt="user"
                />
                Adam Smith
              </div>
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
              >
                SPENDINGS:
              </span>
              $18,500 USD
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold d-flex"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
              >
                OTHER TEAM MEMBERS:
              </span>
              <div class="users-list d-flex align-items-center">
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user14.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div
                  class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold"
                >
                  P
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user8.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user3.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
              </div>
            </li>
          </ul>
          <router-link
            to="/projects-grid"
            class="view-btn p-12 p-sm-15 p-md-20 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary"
          >
            <span class="position-relative">View All Project</span>
            <i class="ph ph-arrow-right"></i>
          </router-link>
          <div
            class="progress mt-15 mt-md-20"
            role="progressbar"
            aria-valuenow="38"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 38%"></div>
          </div>
          <div
            class="mt-8 mt-md-10 d-flex justify-content-between align-items-center"
          >
            <span class="fs-md-15 text-secondary"
              >Task Done: <span class="text-black">12</span>/35</span
            >
            <span class="text-primary fw-semibold">38%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-6 col-xxxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing projects-teams-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini2.jpg"
                class="rounded-1"
                width="60"
                alt="project"
              />
              <div class="ms-15">
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mb-8 mb-md-10 text-black"
                >
                  Web Design Team
                </h5>
                <span class="d-block text-muted"
                  >Handling <span class="fw-bold">2</span> Projects</span
                >
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <p
            class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16"
          >
            This project combiles all the data and backend information to the
            frontend and also design should be appealing.
          </p>
          <ul class="info ps-0 mb-15 list-unstyled">
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >TEAM LEAD:</span
              >
              <div
                class="user d-flex align-items-center text-secondary fw-semibold"
              >
                <img
                  src="../../../assets/images/user/user2.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle me-8"
                  alt="user"
                />
                Alina Smith
              </div>
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >SPENDINGS:</span
              >
              $20,000 USD
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold d-flex"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >OTHER TEAM MEMBERS:</span
              >
              <div class="users-list d-flex align-items-center">
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user8.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user3.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
              </div>
            </li>
          </ul>
          <router-link
            to="/projects-grid"
            class="view-btn p-12 p-sm-15 p-md-20 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary"
          >
            <span class="position-relative">View All Project</span>
            <i class="ph ph-arrow-right"></i>
          </router-link>
          <div
            class="progress mt-15 mt-md-20"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 50%"></div>
          </div>
          <div
            class="mt-8 mt-md-10 d-flex justify-content-between align-items-center"
          >
            <span class="fs-md-15 text-secondary"
              >Task Done: <span class="text-black">25</span>/50</span
            >
            <span class="text-primary fw-semibold">50%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-6 col-xxxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing projects-teams-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini3.jpg"
                class="rounded-1"
                width="60"
                alt="project"
              />
              <div class="ms-15">
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mb-8 mb-md-10 text-black"
                >
                  Front End Team
                </h5>
                <span class="d-block text-muted"
                  >Handling <span class="fw-bold">9</span> Projects</span
                >
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <p
            class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16"
          >
            This project combiles all the data and backend information to the
            frontend and also design should be appealing.
          </p>
          <ul class="info ps-0 mb-15 list-unstyled">
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >TEAM LEAD:</span
              >
              <div
                class="user d-flex align-items-center text-secondary fw-semibold"
              >
                <img
                  src="../../../assets/images/user/user3.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle me-8"
                  alt="user"
                />
                James Andy
              </div>
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >SPENDINGS:</span
              >
              $2,520 USD
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold d-flex"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >OTHER TEAM MEMBERS:</span
              >
              <div class="users-list d-flex align-items-center">
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user11.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div
                  class="rounded-circle bg-warning text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold"
                >
                  P
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user1.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
              </div>
            </li>
          </ul>
          <router-link
            to="/projects-grid"
            class="view-btn p-12 p-sm-15 p-md-20 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary"
          >
            <span class="position-relative">View All Project</span>
            <i class="ph ph-arrow-right"></i>
          </router-link>
          <div
            class="progress mt-15 mt-md-20"
            role="progressbar"
            aria-valuenow="45"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 45%"></div>
          </div>
          <div
            class="mt-8 mt-md-10 d-flex justify-content-between align-items-center"
          >
            <span class="fs-md-15 text-secondary"
              >Task Done: <span class="text-black">45</span>/100</span
            >
            <span class="text-primary fw-semibold">45%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-6 col-xxxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing projects-teams-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini4.jpg"
                class="rounded-1"
                width="60"
                alt="project"
              />
              <div class="ms-15">
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mb-8 mb-md-10 text-black"
                >
                  Backend Team
                </h5>
                <span class="d-block text-muted"
                  >Handling <span class="fw-bold">5</span> Projects</span
                >
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <p
            class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16"
          >
            This project combiles all the data and backend information to the
            frontend and also design should be appealing.
          </p>
          <ul class="info ps-0 mb-15 list-unstyled">
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >TEAM LEAD:</span
              >
              <div
                class="user d-flex align-items-center text-secondary fw-semibold"
              >
                <img
                  src="../../../assets/images/user/user1.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle me-8"
                  alt="user"
                />
                David Warner
              </div>
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >SPENDINGS:</span
              >
              $5,545 USD
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold d-flex"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >OTHER TEAM MEMBERS:</span
              >
              <div class="users-list d-flex align-items-center">
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user9.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user8.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
              </div>
            </li>
          </ul>
          <router-link
            to="/projects-grid"
            class="view-btn p-12 p-sm-15 p-md-20 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary"
          >
            <span class="position-relative">View All Project</span>
            <i class="ph ph-arrow-right"></i>
          </router-link>
          <div
            class="progress mt-15 mt-md-20"
            role="progressbar"
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 90%"></div>
          </div>
          <div
            class="mt-8 mt-md-10 d-flex justify-content-between align-items-center"
          >
            <span class="fs-md-15 text-secondary"
              >Task Done: <span class="text-black">40</span>/45</span
            >
            <span class="text-primary fw-semibold">90%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-6 col-xxxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing projects-teams-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini5.jpg"
                class="rounded-1"
                width="60"
                alt="project"
              />
              <div class="ms-15">
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mb-8 mb-md-10 text-black"
                >
                  Testing Team
                </h5>
                <span class="d-block text-muted"
                  >Handling <span class="fw-bold">4</span> Projects</span
                >
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <p
            class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16"
          >
            This project combiles all the data and backend information to the
            frontend and also design should be appealing.
          </p>
          <ul class="info ps-0 mb-15 list-unstyled">
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >TEAM LEAD:</span
              >
              <div
                class="user d-flex align-items-center text-secondary fw-semibold"
              >
                <img
                  src="../../../assets/images/user/user5.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle me-8"
                  alt="user"
                />
                Sarah Taylor
              </div>
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >SPENDINGS:</span
              >
              $9,542 USD
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold d-flex"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >OTHER TEAM MEMBERS:</span
              >
              <div class="users-list d-flex align-items-center">
                <div
                  class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold"
                >
                  A
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user6.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
              </div>
            </li>
          </ul>
          <router-link
            to="/projects-grid"
            class="view-btn p-12 p-sm-15 p-md-20 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary"
          >
            <span class="position-relative">View All Project</span>
            <i class="ph ph-arrow-right"></i>
          </router-link>
          <div
            class="progress mt-15 mt-md-20"
            role="progressbar"
            aria-valuenow="76"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 76%"></div>
          </div>
          <div
            class="mt-8 mt-md-10 d-flex justify-content-between align-items-center"
          >
            <span class="fs-md-15 text-secondary"
              >Task Done: <span class="text-black">76</span>/100</span
            >
            <span class="text-primary fw-semibold">76%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-6 col-xxxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing projects-teams-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini6.jpg"
                class="rounded-1"
                width="60"
                alt="project"
              />
              <div class="ms-15">
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mb-8 mb-md-10 text-black"
                >
                  Marketing Team
                </h5>
                <span class="d-block text-muted"
                  >Handling <span class="fw-bold">7</span> Projects</span
                >
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <p
            class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16"
          >
            This project combiles all the data and backend information to the
            frontend and also design should be appealing.
          </p>
          <ul class="info ps-0 mb-15 list-unstyled">
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >TEAM LEAD:</span
              >
              <div
                class="user d-flex align-items-center text-secondary fw-semibold"
              >
                <img
                  src="../../../assets/images/user/user1.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle me-8"
                  alt="user"
                />
                Adam Smith
              </div>
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >SPENDINGS:</span
              >
              $18,500 USD
            </li>
            <li
              class="text-secondary d-flex justify-content-between align-items-center fs-md-15 fs-md-16 fw-semibold d-flex"
            >
              <span
                class="text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block"
                >OTHER TEAM MEMBERS:</span
              >
              <div class="users-list d-flex align-items-center">
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user14.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div
                  class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold"
                >
                  P
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user8.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="rounded-circle text-center">
                  <img
                    src="../../../assets/images/user/user3.jpg"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
              </div>
            </li>
          </ul>
          <router-link
            to="/projects-grid"
            class="view-btn p-12 p-sm-15 p-md-20 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary"
          >
            <span class="position-relative">View All Project</span>
            <i class="ph ph-arrow-right"></i>
          </router-link>
          <div
            class="progress mt-15 mt-md-20"
            role="progressbar"
            aria-valuenow="38"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 38%"></div>
          </div>
          <div
            class="mt-8 mt-md-10 d-flex justify-content-between align-items-center"
          >
            <span class="fs-md-15 text-secondary">
              Task Done:
              <span class="text-black">12</span>/35
            </span>
            <span class="text-primary fw-semibold">38%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamsContent",
};
</script>