<template>
  <div class="row justify-content-center centralizado">
    <div class="col-md-6 col-lg-6" id="block-login">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div v-if="error" class="alert alert-danger">{{error}}</div>
        <div class="card-body letter-spacing">
          <p style="text-align: center">
            <img src="https://raw.githubusercontent.com/vicossich/mk3d/main/mk_logo.png" width="40%" alt="Logo MokApp">
          </p>
          <h4 class="text-black fw-bold mb-0 text-center">
            <!-- Sign In To Your Account! --> {{ Lang('login_message') }}
          </h4>
          <form action="#"  @submit.prevent="Login()">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                <!-- Email Address --> {{ Lang('Email_Address') }}
              </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Email"
                name="email"
                value
                required
                autofocus
                :onfocus="addClass()"
                v-model="email"
                autocomplete="off"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                <!-- Password -->{{ Lang('Password') }}
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                name="password"
                required
                v-model="password"
                autocomplete="off"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20"
            >
              <div
                class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1"
              >
                <input
                  class="form-check-input shadow-none"
                  type="checkbox"
                  id="remember-me"
                />
                <label class="form-check-label" for="remember-me">
                  Remember Me
                </label>
              </div>
              <router-link
                to="/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
              >
               <!-- Forgot Password? -->{{ Lang('Forgot_Password') }}
              </router-link>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
              @click="loadingIcon()"
            >
              <!-- Sign In Now --> 
              <img v-if="loadEnable" style="width: 25px;" :src="iconLoading" alt="Loading" />
              <span v-else>{{ Lang('Sign_In_Now') }}</span>
            </button>
            <!--
            <span
              class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16"
            >
              Don’t have an account?
            </span>
            <router-link
              to="/register"
              class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
            >
              Create Account
            </router-link>
            <span
              class="d-block or text-muted text-center mt-15 mb-15 mt-md-20 mb-md-20 fs-md-15 fs-lg-16 position-relative z-1 lh-1"
            >
              <span class="d-inline-block bg-white">Or</span>
            </span>
            <ul class="socials ps-0 mb-0 list-unstyled text-center">
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative facebook border-0 p-0"
                >
                  <i class="ph-fill ph-facebook-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative google border-0 p-0"
                >
                  <i class="ph-bold ph-google-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative twitter border-0 p-0"
                >
                  <i class="ph-fill ph-twitter-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative linkedin border-0 p-0"
                >
                  <i class="ph-fill ph-linkedin-logo"></i>
                </button>
              </li>
            </ul>
            -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref,defineComponent, onMounted, watch  } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
//multi idiomas
import {t as lang, switchLanguage} from '@/plugins/loadMultiLanguage';

export default defineComponent( {
  name: "LoginForm",
  data() {
    return {
      UserOnline: {},
      thisObject: this,
      langString: 'pt',
      iconLoading: './images/loading.gif',
      loadEnable: false,
    }
  },
  mounted(){
    switchLanguage(this.langString);
  },
  setup(){
    const langString: any = (localStorage.getItem('lang_var') != null)? localStorage.getItem('lang_var') : 'pt';
    let isDisabled: boolean = false;
    const email = ref('')
    const password = ref('')
    const error = ref(null)

    const store = useStore() || {};
    const router = useRouter()

    const adicionarClasse = () => {
      const intervalo = setInterval(() => {
        const elemento = document.getElementById('block-login');
        if (elemento) {
          elemento.classList.add('.centralizado');
        } else {
          console.log("Deu ruim")
        }
      }, 1000);
    }
    onMounted(() => {
      adicionarClasse();
    });

    // Observar mudanças em 'inputText'
    watch(langString, (newValue, oldValue) => {
      switchLanguage(newValue);
    });
    
    const Login = async () => {
      
      function TempSave(user) {
        isDisabled = true;
        const userString = JSON.stringify({'email': user.user.data.email, 'uuid': user.user.data.uid});
        console.log(userString);
        localStorage.setItem('temp_access', "");
        localStorage.setItem('temp_access', userString);
      }
      try {
        await store.dispatch('logIn', {
          email: email.value,
          password: password.value
        });
        
        TempSave(store.getters);
        isDisabled = true;
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 2000);
      }
      catch (err: any) {
        error.value = err.message
        
      }
    }
    return { Login, email, password, error, adicionarClasse, langString, isDisabled }
  },
  methods: {
    addClass(){
      const elemento = document.getElementById('block-login');
      if (elemento) {
        elemento.classList.add('.centralizado');
      } else {
        console.log("Deu ruim")
      }
    },
    Lang(param1: string, param2: string=""){
      return lang(param1, param2);
    },
    loadingIcon(){
      this.loadEnable = true;
      setTimeout(() => {
        this.loadEnable = false;
      }, 3500);
    },
  },
});
document.body.classList.remove("sidebar-hide");
document.body.classList.add("sidebar-hide");
document.body.classList.remove("header-hide");
document.body.classList.add("header-hide");
</script>
<style scoped>
@media (min-width: 900px) {
    .centralizado {
        display: flex !important;
        justify-content: center  !important;
        align-items: center  !important;
    }
}
</style>