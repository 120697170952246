<template>
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <h4 class="mb-5 mb-sm-0 fw-bold">{{ Lang}}</h4>

    </div>
    <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
        <small>{{ graph.yLabel }}</small>
    </div>
    <div class="card">
        <div id="chartPerformance" class="chart">

        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import Plotly from 'plotly.js-dist-min';
/* eslint-disable */
export default defineComponent({
    name: "PerformanceGraph",
    mounted() {
        this.createHistoryChart1signals(
            this.graph.dates,
            this.graph.values,
            'jump_heights_max',
            this.graph.lowerLimit,
            this.graph.upperLimit,
            this.graph.yLabel,
            'chartPerformance',
            this.graph.directionChange,
            this.graph.positiveChangeColor,
            this.graph.netagativeChangeColor
        );
    },
    props: {
        graph: {
            type: Object as PropType<{
                dates: any;
                values: any;
                varName: string;
                lowerLimit: number;
                upperLimit: number;
                yLabel: any;
                elementId: string;
                directionChange: any;
                positiveChangeColor?: string;
                netagativeChangeColor?: string;
            }>,
            required: true,
        },
        Lang: {
            type: String
        },
    },
    methods: {

        calculateMean(values: [] | any | undefined) {
            /**
             * Calculates the mean (average) of an array of numerical
             *
             * @throws {Error} If the array is empty, the function will return NaN because division by zero is undefined.
             */
            if (values === undefined || values === null) {
                console.error("A variável values é undefined ou null.");
                return undefined;
            }

            let sum = 0;

            for (let i = 0; i < values.length; i++) {
                sum += values[i];
            }

            let mean = sum / values.length;

            return mean;
        },
        calculateMinMax(values:  [] | any | undefined) {

            /**
             * Calculates the minimum and maximum values in an array of numbers.
             *
             * @param {number[]} values - An array of numbers for which the minimum and maximum values are to be calculated.
             * @returns {{min: number|null, max: number|null}} An object containing the minimum (`min`) and maximum (`max`) values in the array.
             *                                                  If the array is empty, both `min` and `max` will be `null`.
             *
             * @example
             * // Example usage:
             * const array = [10, 20, 30, 5, 40];
             * const range = calculateMinMax(array);
             * console.log(range.min); // Output: 5
             * console.log(range.max); // Output: 40
             *
             * @throws {Error} This function does not throw an error but returns `{ min: null, max: null }` for empty arrays.
             */
            if (values === undefined || values === null) {
                console.error("A variável values é undefined ou null.");
                return undefined;
            }

            if (values.length === 0) {
                return { min: null, max: null }; // Handle empty array case
            }

            let min = values[0];
            let max = values[0];

            for (let i = 1; i < values.length; i++) {
                if (values[i] < min) {
                    min = values[i];
                }
                if (values[i] > max) {
                    max = values[i];
                }
            }

            return { min: min, max: max };
        },
        calculateyRange(upperLimit, lowerLimit, range, mean) {
            /**
             * Dynamically calculates the y-axis range for a chart based on provided limits and data range.
             *
             * This function adjusts the y-axis range (`yRange`) based on the presence or absence of `upperLimit` 
             * and `lowerLimit` values. If either limit is not provided (i.e., `null`), the function will use the 
             * data range (`range.min` and `range.max`) to determine the y-axis range. The function also takes into 
             * account whether the mean value is negative or positive to adjust the range accordingly.
             *
             * @param {number|null} upperLimit - The upper threshold value for the y-axis. Can be `null` if not provided.
             * @param {number|null} lowerLimit - The lower threshold value for the y-axis. Can be `null` if not provided.
             * @param {{min: number, max: number}} range - An object containing the minimum and maximum values of the data range.
             * @returns {Array<number>} An array with two elements representing the calculated y-axis range `[min, max]`.
             *
             * @example
             * // Example usage:
             * const upperLimit = 30;
             * const lowerLimit = 10;
             * const range = { min: 5, max: 35 };
             * const yRange = calculateyRange(upperLimit, lowerLimit, range);
             * console.log(yRange); // Output: [7, 39] or something similar depending on the mean value
             *
             * @throws {Error} The function assumes the presence of a global `mean` variable which affects the calculation.
             * Make sure that `mean` is defined in the context where this function is called.
             */

            let yRange: any;

            // Handle the yRange dynamically
            if (upperLimit === null || lowerLimit === null) {
                // If there are no upper and lower limits
                if (mean < 0) {
                    yRange = [range.min * 1.3, range.max * 0.7];
                } else {
                    yRange = [range.min * 0.7, range.max * 1.3];
                }
            } else {
                if (mean < 0) {
                    yRange = [lowerLimit * 1.3, upperLimit * 0.7];
                } else {
                    yRange = [lowerLimit * 0.7, upperLimit * 1.3];
                }
            }

            return yRange;
        },
        createHistoryChart1signals(dates,
            values: string[] =[],
            varName,
            upperLimit,
            lowerLimit,
            yLabel,
            elementId,
            directionChange,
            positiveChangeColor = '#00E396',
            negativeChangeColor = '#FF4560') {

            /**
             * Creates a historical chart using Plotly.js with specified data and configuration.
             * https://plotly.com/javascript/
             * 
             * Código criado para criar gráficos históricos com apenas um sinal (trace). Por exemplo,
             * valores de altura de salto.
             *
             * @param {Array} dates - An array of date strings to be used as the x-axis labels.
             * @param {Array} values - An array of numeric values corresponding to the dates, to be plotted on the y-axis.
             * @param {string} varName - The name of the variable being plotted, used as the trace name in the chart.
             * @param {number} upperLimit - The upper threshold value to define the upper shaded region in the chart.
             * @param {number} lowerLimit - The lower threshold value to define the lower shaded region in the chart.
             * @param {Array} yRange - An array with two elements [min, max] defining the range of the y-axis.
             * @param {string} yLabel - The label for the y-axis.
             * @param {string} elementId - The ID of the HTML element where the chart will be rendered.
             * @param {string} directionChange - Specifies whether the trend is 'positive' or 'negative'. Determines the color scheme for the shaded regions.
             * @param {string} [positiveChangeColor='#00E396'] - Optional. The color used for the positive change shaded region.
             * @param {string} [negativeChangeColor='#FF4560'] - Optional. The color used for the negative change shaded region.
             *
             * The function plots a line chart with markers using the provided data. It highlights the regions above and below
             * specified thresholds (upperLimit and lowerLimit) with different colors depending on the `directionChange` value.
             * The chart is responsive and will resize automatically when the window is resized.
             */

            ////////////////////////////////////////////////////////////////////////////////////////
            // --- Atenção //
            // directionChange = 'positive' | 'negative'
            // para a maioria das variáveis se deve passar o valor directionChange = 'positive' 
            // variáveis que tem um valor negativo ou que reduzir o valor representa uma melhora devem ser passadas com directionChange = 'negative'
            ////////////////////////////////////////////////////////////////////////////////////////


            // TODO para versão 2
            // limitar o número de pontos sendo renderizados
            // criar opções de filtro por semana / mês / ano 
            // showing max 10 points???

            // calculate the mean value
            let mean = this.calculateMean(values);

            // calculate the min and max
            let range = this.calculateMinMax(values);

            // calculate the yRange
            let yRange = this.calculateyRange(upperLimit, lowerLimit, range, mean)

            // controla o trace 1
            let trace = {
                x: dates,
                y: values,
                mode: 'lines+markers+text',
                name: varName,
                line: { color: '#000', width: 2 },
                marker: {
                    size: 10,
                    color: '#fff',
                    line: { color: '#000', width: 2 }
                },
                text: values.map((value:any) => value.toString()),
                textposition: 'top center',
                textfont: { size: 12, color: '#000' },
                hoverinfo: 'none'  // Disable tooltips
            };

            // controla o shape
            let shapes = []
            if (upperLimit !== null && lowerLimit !== null) {
                if (directionChange === 'positive') {
                    shapes = [
                        {
                            type: 'rect',
                            xref: 'paper',
                            x0: 0,
                            x1: 1,
                            y0: upperLimit,
                            y1: upperLimit + 1,
                            fillcolor: positiveChangeColor,
                            opacity: 0.1,
                            line: { width: 0 }
                        },
                        {
                            type: 'rect',
                            xref: 'paper',
                            x0: 0,
                            x1: 1,
                            y0: lowerLimit + 1,
                            y1: lowerLimit,
                            fillcolor: negativeChangeColor,
                            opacity: 0.1,
                            line: { width: 0 }
                        }

                    ] as any;
                } else {
                    shapes = [
                        {
                            type: 'rect',
                            xref: 'paper',
                            x0: 0,
                            x1: 1,
                            y0: upperLimit,
                            y1: upperLimit + 1,
                            fillcolor: negativeChangeColor,
                            opacity: 0.1,
                            line: { width: 0 }
                        },
                        {
                            type: 'rect',
                            xref: 'paper',
                            x0: 0,
                            x1: 1,
                            y0: lowerLimit + 1,
                            y1: lowerLimit,
                            fillcolor: positiveChangeColor,
                            opacity: 0.1,
                            line: { width: 0 }
                        }

                    ] as any;
                }
            }




            // controla o layout
            let layout = {
                yaxis: { title: yLabel, range: yRange, autorange: true },
                xaxis: { showgrid: false },
                shapes: shapes,
                plot_bgcolor: '#fff',
                paper_bgcolor: '#fff',
                showlegend: false,
                margin: { l: 50, r: 20, t: 20, b: 50 },
                // Specify buttons to remove
            };

            // criar a o plot 
            Plotly.newPlot(elementId, [trace], layout,
                {
                    modeBarButtonsToRemove: ['toImage', 'zoom2d',
                        'pan2d', 'select2d',
                        'lasso2d', 'hoverClosestCartesian',
                        'hoverCompareCartesian', 'toggleSpikelines']
                },
                { responsive: true });

            window.onresize = function () {
                const elementIdTag = document.getElementById(elementId);
                Plotly.relayout(elementIdTag, {
                    'xaxis.autorange': true,
                    'yaxis.autorange': true
                });
            };
        }
    },
});

</script>
<style>
body {
    background: #f2f1f9;
}

.card {
    padding: 10px;
    background: #fff;
    margin-bottom: 20px;
}

.chart {
    width: 100%;
    height: 400px;
    
}
</style>