import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-4" }
const _hoisted_3 = { class: "col-lg-4" }
const _hoisted_4 = { class: "col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_ExpectedEarnings = _resolveComponent("ExpectedEarnings")!
  const _component_DueTicketsToday = _resolveComponent("DueTicketsToday")!
  const _component_NewContact = _resolveComponent("NewContact")!
  const _component_AverageDailySales = _resolveComponent("AverageDailySales")!
  const _component_ClosedTickets = _resolveComponent("ClosedTickets")!
  const _component_NewUsers = _resolveComponent("NewUsers")!
  const _component_OnHoldTicket = _resolveComponent("OnHoldTicket")!
  const _component_AnsweredTickets = _resolveComponent("AnsweredTickets")!
  const _component_NewLeads = _resolveComponent("NewLeads")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Mixed Charts" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ExpectedEarnings),
        _createVNode(_component_DueTicketsToday),
        _createVNode(_component_NewContact)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AverageDailySales),
        _createVNode(_component_ClosedTickets),
        _createVNode(_component_NewUsers)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OnHoldTicket),
        _createVNode(_component_AnsweredTickets),
        _createVNode(_component_NewLeads)
      ])
    ])
  ], 64))
}