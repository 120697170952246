<template>
  <BreadCrumb PageTitle="Card View" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default defineComponent({
  name: "CardViewPage",
  components: {
    BreadCrumb,
  },
});
</script>