<template>
  <div class="card mb-25 border-0 rounded-0 bg-white event-speakers-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-15 mb-sm-20 mb-md-25 mb-lg-30">
        <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
          Speakers
        </h5>
      </div>
      <ul class="ps-0 mb-0 list-unstyled">
        <li class="d-sm-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="../../../assets/images/user/user7.jpg"
              width="50"
              height="50"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-12">
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1">
                Victor James
              </span>
              <span class="d-block text-muted">
                Working In
                <span class="fw-semibold">Google</span>
              </span>
            </div>
          </div>
          <span class="mt-12 mt-sm-0 badge text-outline-primary">
            Event Manager
          </span>
        </li>
        <li class="d-sm-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="../../../assets/images/user/user1.jpg"
              width="50"
              height="50"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-12">
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1">
                Jonathon Ronan
              </span>
              <span class="d-block text-muted">
                Working In
                <span class="fw-semibold">Meta</span>
              </span>
            </div>
          </div>
          <span class="mt-12 mt-sm-0 badge text-outline-primary">
            Event Co-ordinator
          </span>
        </li>
        <li class="d-sm-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="../../../assets/images/user/user8.jpg"
              width="50"
              height="50"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-12">
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1">
                Skyler White
              </span>
              <span class="d-block text-muted">
                Working In
                <span class="fw-semibold">Instagram</span>
              </span>
            </div>
          </div>
          <span class="mt-12 mt-sm-0 badge text-outline-primary">
            Software Engineer
          </span>
        </li>
        <li class="d-sm-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="../../../assets/images/user/user2.jpg"
              width="50"
              height="50"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-12">
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1">
                Angela Carter
              </span>
              <span class="d-block text-muted">
                Working In
                <span class="fw-semibold">YouTube</span>
              </span>
            </div>
          </div>
          <span class="mt-12 mt-sm-0 badge text-outline-primary">
            UI/UX Designer
          </span>
        </li>
        <li class="d-sm-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="../../../assets/images/user/user5.jpg"
              width="50"
              height="50"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-12">
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1">
                Jane Ronan
              </span>
              <span class="d-block text-muted">
                Working In
                <span class="fw-semibold">Linkedin</span>
              </span>
            </div>
          </div>
          <span class="mt-12 mt-sm-0 badge text-outline-primary">
            Project Manager
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventSpeakers",
};
</script>